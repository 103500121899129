export type PatientReferralFormPromptContextKey =
  | 'type_of_referral'
  | 'referring_provider_info'
  | 'referral_info'
  | 'provider_referral_email_sent'
  | 'provider_referral_email_scheduled'
  | 'share_friend_referral_link'
  | 'provider_organization'
  | 'referral_email'
  | 'referral_first_name'
  | 'referral_last_name'
  | 'referral_birthday'
  | 'referral_release_date'
  | 'referral_gender'
  | 'referral_phone'
  | 'referral_state'
  | 'referring_partner_info'
  | 'email_acknowledgement'
  | 'provider_full_name'
  | 'provider_email'
  | 'provider_role'
  | 'provider_phone'
  | 'partnership_interest'
  | 'provider_state'
  | 'provider_city'
  | 'correctional_facility_form'
  | 'correctional_facility_intake_visit_schedule'
  | 'correctional_facility_intake_visit_confirmed'
  | 'spring_health_partner_form'
  | 'schedule_intake_visit'
  | 'schedule_intake_visit_calendar'
  | 'schedule_intake_visit_timezone'
  | 'schedule_intake_visit_referral_type'

export type PartnerReferralFormPromptContextKey = 'referring_partner_info' | 'partner_referral_sent'

export type PatientReferralFormPromptResponseContextKey =
  | TypeOfReferralResponseContextKey
  | 'custom'

type TypeOfReferralResponseContextKey =
  | 'type_of_referral_provider'
  | 'type_of_referral_provider_with_patient'
  | 'type_of_referral_correctional_facility'
  | 'type_of_referral_inpatient_facility'
  | 'type_of_referral_friend'
  | 'type_of_referral_spouse_partner'
  | 'type_of_referral_colleague'
  | 'type_of_referral_family'
  | 'type_of_referral_self'

export type SpringHealthReferralFormPromptContextKey =
  | 'spring_health_id'
  | 'is_billed_by_spring_health'
