import { Box, Flex, Group, ResponsiveBox, Space, Stack, Text, TitleTwo } from '@shared/components'
import React from 'react'
import { PromptContentNavigation } from '../PromptContentNavigation'
import { WidgetProps } from '../PromptContentWidget'

export const NotAlone = ({ onSubmit, onBack }: WidgetProps) => {
  const submitHandler = () => {
    onSubmit({
      value: {
        contextKey: 'custom',
        value: 'not_alone',
      },
    })
  }

  return (
    <Stack spacing='md'>
      <Stack spacing='xs'>
        <TitleTwo>You’re not alone.</TitleTwo>
        <Text>About 3 million Americans struggle with opioid use.</Text>
      </Stack>
      <Space h='xs' />

      <Stack spacing='sm'>
        <Text bold>The good news?</Text>
        <ResponsiveBox
          mobile={
            <Stack spacing='xs' align='center'>
              <StatsCard
                primaryText='79%'
                secondaryText='of Ophelia patients are still in treatment with us at 6 months'
                annotation='More than 2x national average'
              />
              <StatsCard
                primaryText='97%'
                secondaryText='of Ophelia patients in care at 6 months are not using opioids'
              />
            </Stack>
          }
          desktop={
            <Group noWrap align='stretch'>
              <StatsCard
                primaryText='79%'
                secondaryText='of Ophelia patients are still in treatment with us at 6 months'
                annotation='More than 2x national average'
              />
              <StatsCard
                primaryText='97%'
                secondaryText='of Ophelia patients in care at 6 months are not using opioids'
              />
            </Group>
          }
        />
      </Stack>

      <PromptContentNavigation onBack={onBack} onSubmit={submitHandler} />
    </Stack>
  )
}

const StatsCard = ({
  primaryText,
  secondaryText,
  annotation,
}: {
  primaryText: string
  secondaryText: string
  annotation?: string
}) => {
  return (
    <Flex sx={{ width: '100%', minHeight: '100%' }} p='sm'>
      <Box
        sx={({ other: { colors, sizes }, radius }) => ({
          borderWidth: sizes.border.md,
          borderStyle: 'solid',
          borderColor: colors.text[2],
          borderRadius: radius.sm,
          width: '100%',
          alignContent: 'left',
          textAlign: 'left',
        })}
      >
        <Stack spacing='xs' align='left' p='md'>
          <TitleTwo color={colors => colors.actions[0]}>{primaryText}</TitleTwo>
          <Text>{secondaryText}</Text>
          {annotation && (
            <Text size='xs' color={colors => colors.text[1]}>
              {annotation}
            </Text>
          )}
        </Stack>
      </Box>
    </Flex>
  )
}
