import { useForm } from '@mantine/form'
import {
  Alert,
  Radio,
  RadioGroup,
  ShieldIcon,
  Stack,
  Text,
  TitleTwo,
  isAnySelected,
} from '@shared/components'
import { PromptResponseContextKey } from '@shared/types'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PromptContentNavigation } from '../PromptContentNavigation'
import { WidgetProps } from '../PromptContentWidget'

const OPIOID_USE_FREQUENCY_OPTIONS = {
  opioid_use_frequency_daily: 'opioid_use_frequency_daily',
  opioid_use_frequency_several_times_per_week: 'opioid_use_frequency_several_times_per_week',
  opioid_use_frequency_several_times_per_month: 'opioid_use_frequency_several_times_per_month',
  opioid_use_frequency_not_currently_using: 'opioid_use_frequency_not_currently_using',
} as const

export type OpioidUseFrequencyOptions = keyof typeof OPIOID_USE_FREQUENCY_OPTIONS

const opioidUseFrequencyTextMapping = {
  opioid_use_frequency_daily: {
    label: 'Daily',
  },
  opioid_use_frequency_several_times_per_week: {
    label: 'Several times per week',
  },
  opioid_use_frequency_several_times_per_month: {
    label: 'Several times per month',
  },
  opioid_use_frequency_not_currently_using: {
    label: `I'm not currently using opioids`,
  },
} as const

type OpioidUseFrequencyFormType = {
  opioid_use_frequency: PromptResponseContextKey
}

export const OpioidUseFrequency = ({ response, onSubmit }: WidgetProps) => {
  const navigate = useNavigate()
  const form = useForm<OpioidUseFrequencyFormType>({
    initialValues: {
      opioid_use_frequency: response?.value.contextKey as PromptResponseContextKey,
    },
    validate: {
      opioid_use_frequency: isAnySelected(Object.keys(OPIOID_USE_FREQUENCY_OPTIONS), 'Required'),
    },
  })

  const showAlert =
    form.values.opioid_use_frequency === OPIOID_USE_FREQUENCY_OPTIONS.opioid_use_frequency_daily ||
    form.values.opioid_use_frequency ===
      OPIOID_USE_FREQUENCY_OPTIONS.opioid_use_frequency_several_times_per_week

  const submitHandler = () => {
    if (form.validate().hasErrors) {
      return
    }

    onSubmit({
      value: {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        contextKey: form.values.opioid_use_frequency as PromptResponseContextKey,
      },
      opioid_use_frequency: {
        contextKey: 'custom',
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        value: form.values.opioid_use_frequency as PromptResponseContextKey,
      },
    })
  }

  return (
    <Stack spacing='md' test-id='content:opioid-use-frequency-form'>
      <TitleTwo>How frequently are you using opioids?</TitleTwo>
      <Text>
        We adapt our treatment to make sure you receive support that aligns with your experience.
      </Text>
      <RadioGroup {...form.getInputProps('opioid_use_frequency')}>
        {Object.entries(opioidUseFrequencyTextMapping).map(([key, { label }]) => (
          <Stack spacing='sm' key={key}>
            <Radio
              value={OPIOID_USE_FREQUENCY_OPTIONS[key as OpioidUseFrequencyOptions]}
              label={label}
            />
          </Stack>
        ))}
      </RadioGroup>
      {showAlert && (
        <Alert
          title='You won’t have to navigate this alone.'
          variant='primary'
          icon={<ShieldIcon />}
        >
          We provide deep induction support, so you’ll have step-by-step guidance through the
          initial stages.
        </Alert>
      )}
      <PromptContentNavigation onBack={() => navigate(-1)} onSubmit={submitHandler} />
    </Stack>
  )
}
