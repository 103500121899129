import {
  Alert,
  ArrowRightIcon,
  Box,
  Divider,
  Group,
  InfoIcon,
  PrimaryButton,
  ResponsiveBox,
  SecondaryButton,
  Stack,
  Text,
  TitleTwo,
  emojiMap,
  useLifecycle,
} from '@shared/components'
import { REFERRAL_PARAM } from '@shared/types'
import React from 'react'
import { useQuery } from 'react-query'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import * as FullStory from '../../../src/common/fullstory'
import { patientApi, referralsApi } from '../../common/api'
import { ContactSupport, LearnMore, MoreOptions, Skeletons } from '../../common/components'
import { useFlags, usePortalDims, useQueryParams } from '../../common/hooks'
import { logger } from '../../common/logger'
import { routes } from '../../common/routes'
import { analytics, sendPageEvent, sendTrackEvent } from '../../common/rudderstack'
import {
  getLocalStorageItem,
  getSessionStorageItem,
  removeSessionStorageItem,
  setLocalStorageItem,
} from '../../common/storage'
import { ReferralHowOpheliaWorks, ReferralInsurance, ReferralIntro } from './Referral'
import { CheckedInsuranceLocationStateType } from './Welcome'

export const WelcomeVideo = () => {
  const params = useQueryParams()
  const navigate = useNavigate()
  const { isMobile, isDesktop } = usePortalDims()
  const isReferral = params.has(REFERRAL_PARAM)
  const location = useLocation()
  const locationState = location.state as CheckedInsuranceLocationStateType
  const checkedInsurance = locationState?.checkedInsurance

  const { referralMoreInfo } = useFlags()

  const { data: personalizedReferralData, isLoading: isPersonalizedReferralLoading } = useQuery(
    ...patientApi.getQuery('GET /referral/friend-or-family/:referralId/personalization', {
      params: { referralId: params.get(REFERRAL_PARAM) as string },
    }),
    { enabled: isReferral },
  )
  const showReferrerMessage = personalizedReferralData?.personalizedReferral?.showReferrerMessage
  let personalizedReferralMessage = ''
  let personalizedReferrerName = 'Your friend'
  if (showReferrerMessage && personalizedReferralData?.personalizedReferral) {
    personalizedReferralMessage = personalizedReferralData.personalizedReferral.referrerMessage
    personalizedReferrerName = personalizedReferralData.personalizedReferral.referrerName
  }

  const handleTrackEvents = (referralId: string | null, existingReferralId: string | null) => {
    const data: analytics.apiObject = {}

    if (referralId) {
      data.referralId = referralId
      void referralsApi.addReferralClickEvent(referralId)
      sendTrackEvent('Referral Link Clicked', data)
      FullStory.event('Referral Link Clicked')
      setLocalStorageItem('wxflow_ref', referralId)

      if (existingReferralId && existingReferralId !== referralId) {
        data.existingReferralId = existingReferralId
        FullStory.event('Referral Link Clicked & Existing Referral Found')
        sendTrackEvent('Referral Link Clicked & Existing Referral Found', data)
      }
    } else if (existingReferralId) {
      data.existingReferralId = existingReferralId
      FullStory.event('Existing Referral Found')
      sendTrackEvent('Existing Referral Found', data)
    }
    return data
  }

  useLifecycle({
    onMount: () => {
      const referralIdFromParams = params.get(REFERRAL_PARAM)
      const existingReferralIdFromSessionStorage = getLocalStorageItem('wxflow_ref')
      const provider = getSessionStorageItem('referral_workflow_prompt_response')
      if (provider) {
        removeSessionStorageItem('referral_workflow_prompt_response')
      }

      if (getSessionStorageItem('welcome_call_topics_survey')) {
        removeSessionStorageItem('welcome_call_topics_survey')
      }

      const eventData = handleTrackEvents(
        referralIdFromParams,
        existingReferralIdFromSessionStorage,
      )
      logger.info('Welcome', { tags: { workflow: 'onboarding' } })
      sendPageEvent('Welcome', eventData)
      FullStory.event('[Welcome Video] Page View')
      FullStory.event('Welcome Page View', {
        variation: 'welcome_video_experiment',
      })
    },
  })

  if (isPersonalizedReferralLoading) {
    return <Skeletons />
  }

  const handleClickCheckInsurance = () => {
    sendTrackEvent('Click Check My Insurance On Welcome Page')
    FullStory.event('Click Check My Insurance On Welcome Page')
    navigate(routes.insuranceCoverage.index)
  }

  const onGetStarted = () => {
    navigate(routes.welcome.children.signup)
  }

  const CoverageAlert = (
    <Alert
      icon={<InfoIcon />}
      actionIcon={<ArrowRightIcon />}
      onClick={() => handleClickCheckInsurance()}
      variant='primary'
      title={
        <Text bold color={colors => colors.text[2]}>
          Check my coverage
        </Text>
      }
    >
      <Stack>
        <Text color={colors => colors.text[2]} size='xs'>
          Let&apos;s check your insurance to find out if your care might be covered.
        </Text>
      </Stack>
    </Alert>
  )

  return (
    <Stack
      style={{
        /**
         * Add margin bottom so that the floating buttons don't cover
         * the content at the bottom of the page.
         */
        marginBottom: isMobile ? '100px' : '0',
      }}
      test-id='page:welcome-call'
      spacing='sm'
    >
      {showReferrerMessage && (
        <Alert variant='primary' mb='md'>
          <Stack>
            <Text size='md' color={colors => colors.text[2]}>
              {personalizedReferralMessage}
            </Text>
            <Text size='xs' color={colors => colors.text[2]}>
              - {personalizedReferrerName}
            </Text>
          </Stack>
        </Alert>
      )}
      {isReferral && referralMoreInfo && (
        <Stack>
          <ReferralIntro />
          <Divider my='md' />
          <ReferralHowOpheliaWorks />
          <Divider my='md' />
          <ReferralInsurance />
          <Divider my='md' />
        </Stack>
      )}
      <Stack spacing='lg'>
        <Stack spacing='xs'>
          {isReferral ? (
            <TitleTwo>
              {emojiMap['waving hand']} {personalizedReferrerName} wants you to try Ophelia
            </TitleTwo>
          ) : (
            <TitleTwo>
              {emojiMap['waving hand']} Join 20,000+ patients on their journey to recovery
            </TitleTwo>
          )}
          <Text>
            Curious about how our treatment is making a difference? Hear from our patients:
          </Text>
        </Stack>
        <Box
          sx={{
            aspectRatio: isMobile ? '9 / 16' : '16 / 9',
            width: '100%',
            height: '100%',
          }}
        >
          <video
            playsInline
            controls
            height='100%'
            width='100%'
            style={{
              objectPosition: 'center',
              objectFit: isMobile ? 'cover' : 'contain',
            }}
            preload='auto'
            src='https://firebasestorage.googleapis.com/v0/b/ophelia-production.appspot.com/o/public%2Fwelcome_video_md.mp4?alt=media&token=883a7b2b-4c48-4f56-9c4e-c0cbaeaca8b8'
            poster='https://firebasestorage.googleapis.com/v0/b/ophelia-production.appspot.com/o/public%2Fwelcome_video_poster.png?alt=media&token=04efd437-e9fb-4c3a-837e-1fb8450a0322'
            onPlay={() => FullStory.event('[Welcome Video] Video Started')}
            onWaiting={() => FullStory.event('[Welcome Video] Video Loading')}
            onEnded={() => FullStory.event('[Welcome Video] Video Ended')}
            onClick={() => FullStory.event('[Welcome Video] Video Clicked')}
            onTouchStart={() => FullStory.event('[Welcome Video] Video Clicked')}
          >
            Your browser does not support the video tag.
          </video>
        </Box>
        <Stack spacing='sm'>
          <TitleTwo>Ready to take the next step?</TitleTwo>
          <Text>
            {`Let's set you up with a free 15-minute welcome call. First, we have a few questions that
            should take less than 5 minutes to answer.`}
          </Text>
        </Stack>
        {!checkedInsurance && isMobile && CoverageAlert}
        <ResponsiveBox
          mobile={
            <Stack spacing='lg'>
              <Group
                style={{
                  width: 'calc(100dvw - 64px)',
                  position: 'fixed',
                  bottom: '32px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  zIndex: 1000,
                }}
              >
                <SecondaryButton
                  test-id='button:sign-in@mobile'
                  component={Link}
                  to={routes.welcome.children.signin}
                  style={{
                    background: 'black',
                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                  }}
                >
                  Sign in
                </SecondaryButton>
                <PrimaryButton
                  style={{
                    flex: 2,
                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                  }}
                  test-id='button:continue@mobile'
                  onClick={onGetStarted}
                >
                  Get started
                </PrimaryButton>
              </Group>
              <Stack spacing='md' align='center'>
                {/* Referrals do not need to see sign in button */}
                {isReferral && <MoreOptions />}
              </Stack>
              {/* Only show contact support on mobile - desktop has it in the header */}
              <ContactSupport />
              {/* Referrals do not need this because it's the other CTA already */}
              {!isReferral && <LearnMore />}
            </Stack>
          }
          desktop={
            <Group position={isReferral ? 'right' : 'apart'}>
              {/* Referrals do not need this because it's the other CTA already */}
              {!isReferral && <LearnMore />}
              <Group>
                {/* Referrals do not need to see sign in button */}
                {isReferral ? (
                  <MoreOptions />
                ) : (
                  <SecondaryButton
                    test-id='button:sign-in@desktop'
                    component={Link}
                    to={routes.welcome.children.signin}
                  >
                    Sign in
                  </SecondaryButton>
                )}
                <PrimaryButton test-id='button:continue@desktop' onClick={onGetStarted}>
                  Get started
                </PrimaryButton>
              </Group>
            </Group>
          }
        />
        {!checkedInsurance && isDesktop && CoverageAlert}
      </Stack>
    </Stack>
  )
}
