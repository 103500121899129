import { useForm } from '@mantine/form'
import {
  ArrowLeftIcon,
  Box,
  Group,
  PrimaryButton,
  ResponsiveBox,
  Stack,
  TertiaryButton,
  Textarea,
} from '@shared/components'
import { PromptResponseModel } from '@shared/types'
import React from 'react'
import { isRequired } from '../../forms'

type Props = {
  value: string | undefined
  onSubmit: (data: PromptResponseModel['payload']) => void
  onBack: (() => void) | undefined
}

type Form = {
  value: string
}

export const PromptContentLongInput = (props: Props) => {
  const form = useForm<Form>({
    initialValues: { value: props.value ?? '' },
    validate: { value: isRequired },
  })

  const onSubmit = () => {
    if (form.validate().hasErrors) {
      return
    }

    props.onSubmit({ value: { contextKey: 'custom', value: form.values.value.trim() } })
  }

  return (
    <Box test-id='content:long-input'>
      <Textarea placeholder='Type here...' {...form.getInputProps('value')} />
      <ResponsiveBox
        mt='lg'
        mobile={
          <Stack spacing='lg' justify='center'>
            <PrimaryButton test-id='button:next@mobile' fullWidth onClick={onSubmit}>
              Next
            </PrimaryButton>
            {props.onBack && (
              <TertiaryButton
                test-id='button:back@mobile'
                leftIcon={<ArrowLeftIcon />}
                style={{ margin: 'auto' }}
                onClick={props.onBack}
                fullWidth
              >
                Back
              </TertiaryButton>
            )}
          </Stack>
        }
        desktop={
          <Box>
            {props.onBack ? (
              <Group position='apart'>
                <TertiaryButton
                  test-id='button:back@desktop'
                  leftIcon={<ArrowLeftIcon />}
                  onClick={props.onBack}
                >
                  Back
                </TertiaryButton>
                <PrimaryButton test-id='button:next@desktop' onClick={onSubmit}>
                  Next
                </PrimaryButton>
              </Group>
            ) : (
              <PrimaryButton test-id='button:next@desktop' onClick={onSubmit}>
                Next
              </PrimaryButton>
            )}
          </Box>
        }
      />
    </Box>
  )
}
