import { ISOString } from '../dates'
import { DatabaseMetadata } from '../models'
import { ReferralPartner } from '../partners'
import { PromptContextKey, PromptResponseContextKey } from './contextKeys'
import { WelcomeCallPromptResponseContextKey } from './contextKeys/welcomeCall'

export const WORKFLOW_TYPES = [
  'onboarding',
  'medical_intake_form',
  'reconnect',
  'patient_referral_form',
  'patient_referral_form_partner',
  'patient_referral_form_spring_health',
  'patient_referral_form_partner_with_patient',
  'phq_8_form',
  'phq_9_form',
  'gad_7_form',
  'barc_10_form',
  'asq_form',
  'not_ready_flow',
] as const

export type WorkflowType = (typeof WORKFLOW_TYPES)[number]

export const WELLNESS_CHECK_WORKFLOW_TYPES = [
  'phq_9_form',
  'phq_8_form',
  'barc_10_form',
  'gad_7_form',
  'asq_form',
] as const

export type WellnessCheckWorkflowType = (typeof WELLNESS_CHECK_WORKFLOW_TYPES)[number]

/**
 * Wellness check workflows are generated on the backend
 * and then shared with patients. Therefore, we need to hoist
 * the URL params to a shared location so the FE and BE can
 * both access them.
 */
export const WELLNESS_CHECK_SEARCH_PARAMS = {
  Token: 'token',
  Type: 'type',
  ZoomUrl: 'zoomUrl',
  AdditionalChecks: 'additionalChecks',
}

export const WELLNESS_CHECK_PATHNAME = 'wellness-check'
export const WELLNESS_CHECK_START = 'start'

export type PatientWorkflows = {
  [key in WorkflowType]?: {
    sessionId: string
    workflowId: string
    status: WorkflowSessionStatus
    metadata?: WorkflowSessionMetadata
  }
}

export const WORKFLOW_SESSION_STATUSES = ['not_started', 'in_progress', 'complete'] as const
export type WorkflowSessionStatus = (typeof WORKFLOW_SESSION_STATUSES)[number]

export type WorkflowSessionMetadata = {
  referralPartnerId?: ReferralPartner['oid']
  highRiskPatient?: boolean
  highRiskPatientResponses?: WelcomeCallPromptResponseContextKey[]
  suboxoneSubscriptionExpirationDate?: ISOString
  /**
   * We track the referral workflow prompt response from
   * unknown providers who refer patients to Ophelia, and
   * select "I am with the patient" in the referral form.
   */
  referralWorkflowPromptResponse?: string
}

export type WorkflowSessionModel = {
  workflowId: string
  // patient who completed the session
  patientId?: string
  // employee who completed the session
  employeeId?: string
  status: WorkflowSessionStatus
  createdAt: string
  completedAt?: string
  metadata?: WorkflowSessionMetadata
  promptPath: string[] | undefined
}

export type WorkflowSession = WorkflowSessionModel & DatabaseMetadata

export type WorkflowRedirects<T extends WorkflowType = WorkflowType> = {
  /** The route that the patient will be redirected to */
  redirectPath: string
  /** Currently, we will only handle redirects where any of the following triggers work */
  operand: 'or'
  triggers: {
    /** The prompt whose response should be evaluated for determining if the patient should be redirected */
    promptId: string
    /** If the patient's response is in the list of responses, the trigger applies */
    responseContextKeys: PromptResponseContextKey<T>[]
  }[]
}[]

export type WorkflowModel<T extends WorkflowType = WorkflowType> = {
  /** Unique, user-defined name for workflow */
  name: string
  type: WorkflowType
  /** Creator of workflow */
  createdBy: string
  deprecatedAt?: string
  redirectsOnCompletion?: WorkflowRedirects<T>
  createdAt: string
} & (WorkflowPromptV1<T> | WorkflowPromptV2<T>)

export type WorkflowPromptV1<T extends WorkflowType = WorkflowType> = {
  prompts: { promptId: string }[]
  version: 1
  deadEnds: {
    /** The prompt that should be presented for this dead end */
    promptId: string
    trigger: {
      /** The prompt whose response should be evaluated for determining if the dead end should be shown */
      promptId: string
      /** If this response is recorded, the next and only prompt is this dead end */
      responseContextKey: PromptResponseContextKey<T>
    }
  }[]
}

export type WorkflowPromptV2<T extends WorkflowType = WorkflowType> = {
  prompts: {
    promptId: string
    branches: PromptBranchingLogic<T>[]
  }[]
  version: 2
}

export type PromptBranchingLogic<T extends WorkflowType = WorkflowType> = {
  responseContextKeys?: PromptResponseContextKey<T>[]
  nextPromptId: string
  /**
   * If no promptContextKey is present, we default
   * the rule to look up by payload.value
   */
  promptContextKey?: PromptContextKey<T>
}

export type Workflow = WorkflowModel & DatabaseMetadata

export type WidgetType =
  | 'payment_method'
  | 'enrollment_call_calendar'
  | 'other_resources'
  | 'call_me_now'
  | 'welcome_call_me_now_confirmation'
  | 'how_did_you_hear_about_us'
  | 'reconnect_call_calendar'
  | 'provider_contact_form'
  | 'referral_contact_form'
  | 'share_friend_referral_link'
  | 'referral_email_sent'
  | 'partner_contact_form'
  | 'partner_with_patient_contact_form'
  | 'partner_with_patient_confirmation'
  | 'provider_referral_sent'
  | 'type_of_referral'
  | 'refer_someone'
  | 'not_ready_alternative_options'
  | 'not_ready_contact_info'
  | 'not_ready_confirmation'
  | 'welcome_call_explanation'
  | 'motivations_for_treatment_form'
  | 'im_with_the_patient'
  | 'who_referred_you'
  | 'current_substances_and_prescribed_opioids_used'
  | 'past_opioid_use_disorder_treatment'
  | 'psych_diagnoses_history'
  | 'correctional_facility_form'
  | 'correctional_facility_intake_visit_schedule'
  | 'correctional_facility_intake_visit_confirmed'
  // v12 start
  | 'how_to_get_started'
  | 'payment_method_intake_visit'
  | 'insurance_verification'
  | 'schedule_intake_visit'
  | 'schedule_welcome_call_care_transfer'
  | 'suboxone_prescribed_by'
  | 'reason_for_new_provider'
  | 'social_determinants_of_health'
  | 'spring_health_partner_form'
  // v13 start
  | 'how_to_connect'
  | 'care_readiness_chat'
  | 'start_welcome_chat'
  // v14 start
  | 'not_alone'
  | 'opioid_use_frequency'
  | 'what_comes_next'
  | 'care_readiness_value_props'
  | 'opioid_use_frequency_value_props'

export type PromptContentHeader = {
  contentType: 'header'
  primaryText?: string
  secondaryText?: string
}

export type PromptContentBanner = {
  contentType: 'banner'
  type: 'error' | 'success' | 'warning'
  label: string
}

export type PromptContentSingleSelect<T extends WorkflowType = WorkflowType> = {
  /** 'response' contentType indicates that this content will be used to form payload */
  contentType: 'response'
  responseType: 'single_selection'
  options: {
    /** The discrete value associated with this option */
    contextKey: PromptResponseContextKey<T>
    /** The text to display for this option */
    primaryText: string
    secondaryText?: string
  }[]
}

export type PromptContentMultipleChoice = {
  contentType: 'response'
  responseType: 'multiple_choice'
  options: {
    /** The discrete value associated with this option */
    contextKey: PromptResponseContextKey
    /** The text to display for this option */
    primaryText: string
    secondaryText?: string
  }[]
}

export type PromptContentNumberInput = {
  contentType: 'response'
  responseType: 'number_input'
}

export type PromptContentShortInput = {
  contentType: 'response'
  responseType: 'short_input'
}

export type PromptContentLongInput = {
  contentType: 'response'
  responseType: 'long_input'
}

export type PromptContentLikertScale = {
  contentType: 'response'
  responseType: 'likert'
  // Likert scale level refers to the number of options given (e.g. level 5: [1] [2] [3] [4] [5])
  level: number
  leftText: string
  rightText: string
  options: {
    /** The discrete value associated with this option */
    contextKey: PromptResponseContextKey
    /** The text to display for this option */
    text: string
  }[]
}

export type PromptContentWidget = {
  /** 'response' contentType indicates that this content will be used to form payload */
  contentType: 'response'
  responseType: 'widget'
  widgetType: WidgetType
}

export type PromptContentResponse =
  | PromptContentSingleSelect
  | PromptContentMultipleChoice
  | PromptContentShortInput
  | PromptContentLongInput
  | PromptContentNumberInput
  | PromptContentLikertScale
  | PromptContentWidget

export type PromptContentBlock<T extends WorkflowType = WorkflowType> =
  | PromptContentHeader
  | PromptContentBanner
  | PromptContentSingleSelect<T>
  | PromptContentMultipleChoice
  | PromptContentShortInput
  | PromptContentLongInput
  | PromptContentNumberInput
  | PromptContentLikertScale
  | PromptContentWidget

export type PromptModel<T extends WorkflowType = WorkflowType> = {
  /** Immutable */
  contextKey: PromptContextKey<T>
  deprecatedAt?: string
  createdAt: string
  updatedAt: string
  content: PromptContentBlock<T>[]
}

export type Prompt<T extends WorkflowType = WorkflowType> = PromptModel<T> & DatabaseMetadata

export type PromptResponseModel<T extends WorkflowType = WorkflowType> = {
  promptId: string
  promptContextKey: PromptContextKey<T>
  sessionId: string
  workflowId: string
  createdAt: string
  updatedAt: string
  payload: PromptResponsePayload<T>
}

export type PromptResponsePayload<T extends WorkflowType = WorkflowType> = {
  /**
   * The key 'value' is guaranteed to be present.
   * It represents the primary answer for a prompt.
   * In the case of a multiple choice questions, the answer
   * is stored here. If there is additional data collected
   * during that prompt, like in the payment method step,
   * it will be stored in this flat object under custom keys.
   */
  value: PromptResponseData<T>
} & {
  [key in PromptContextKey<T>]?: PromptResponseData<T>
}

export type PromptResponseData<T extends WorkflowType = WorkflowType> = {
  /**
   * For prompts like multiple choice, response data
   * contains all selected answers
   */
  contextKey: PromptResponseContextKey<T> | PromptResponseContextKey<T>[]
  /** If contextKey is 'custom', pass the raw value here */
  value?: string | number | boolean | PromptResponseContextKey<T>[]
}

export type PromptResponse<T extends WorkflowType = WorkflowType> = PromptResponseModel<T> &
  DatabaseMetadata

export type FullWorkflowState = {
  promptsAndResponses: {
    prompt: Prompt
    response?: PromptResponse | undefined
  }[]
  workflowSession: WorkflowSession
}
