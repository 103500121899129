import { useContext } from 'react'
import { LDContext } from '../components'
import { useAuth } from './use-auth'

export function useFlags() {
  const { ldClient } = useContext(LDContext)
  const flags = ldClient?.allFlags()
  const { currentUser } = useAuth()

  const isActiveInState = (flagValue: string[]) => {
    return flagValue.includes(currentUser?.data?.homeData?.state ?? '')
  }

  return {
    isActiveInState,
    patientAppTaskUi: flags?.['patient-app-task-ui'],
    paymentPlanSignup: flags?.['payment-plan-signup'],
    partnerWelcomeFlow: flags?.partnerWelcomeFlow,
    welcomeCallCustomPhone: flags?.['welcome-call-custom-phone'],
    referralMoreInfo: flags?.referralMoreInfo,
    redirectLeadsToInsuranceTasks: flags?.['lead-insurance-task'],
    callMeNowCta: flags?.['call-me-now-cta'],
    callUsNowCta: flags?.['call-us-now-cta'],
    welcomeCallStandby: flags?.['welcome-call-standby'],
    defaultWelcomeCallStandby: flags?.['default-welcome-call-standby'] || [],
    /**
     * This flag is used as a kill switch for the welcome video experiment.
     */
    isWelcomeVideoEnabled: flags?.['is-welcome-video-enabled'],
    hideScheduledWelcomeCallsWhileQueueIsOpen:
      flags?.['hide-scheduled-welcome-calls-while-queue-is-open'] || [],
    shouldSendWelcomeCallTopicMessage: flags?.['should-send-welcome-call-topic-message'],
    shouldSeeWelcomeCallConfirmationPageSurvey:
      flags?.['should-see-welcome-call-confirmation-page-survey'],
    accountRecoveryReorg: flags?.['account-recovery-reorg'],
    /**
     * We use a LD segment to determine if a patient IS a care transfer first, and then this flag will split the care transfers into a test/control group for this experiment.
     *
     *https://app.launchdarkly.com/projects/default/flags/new-care-transfer-flow/targeting?env=test&env=production&selected-env=test
     */
    isInCareTransferTestCohort: flags?.['new-care-transfer-flow'],
    /**
     * This is a kill switch for the care transfer experiment.
     * If this flag is off, the care transfer flow will not be shown to any patients.
     */
    careTransferExperiment: flags?.['care-transfer-experiment'],
    /**
     * This is a kill switch for the welcome chat experiment.
     * If this flag is off, the chat option will not be shown to any patients.
     */
    welcomeChatExperiment: flags?.['welcome-chat-experiment'],
    correctionsInpatientForAllStates: Boolean(flags?.['corrections-inpatient-for-all-states']),
  }
}

export function useLDClient() {
  return useContext(LDContext)
}
