export const emojiMap = {
  'index finger pointing up': '☝️',
  'party popper': '🎉',
  'waving hand': '👋',
  'thumbs up': '👍',
  handshake: '🤝',
  'open lock': '🔓',
  sparkles: '✨',
  envelope: '✉️',
  'home with garden': '🏡',
  forbidden: '🚫',
  pen: '🖊️',
} as const
