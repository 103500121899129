import {
  ArrowLeftIcon,
  Box,
  Divider,
  Group,
  PrimaryButton,
  ResponsiveBox,
  Select,
  Space,
  Stack,
  TertiaryButton,
  Text,
  TitleTwo,
  useLifecycle,
} from '@shared/components'
import { states } from '@shared/types'
import React, { useEffect, useState } from 'react'
import { SignupProps } from '.'
import { ReturningToOphelia } from '../../../common/components/ReturningToOphelia'
import { useFlags, usePortalDims } from '../../../common/hooks'
import { useCallMeNowQueueStatus } from '../../../common/hooks/use-call-me-now-queue-status'
import { logger } from '../../../common/logger'
import { sendPageEvent, sendTrackEvent } from '../../../common/rudderstack'
import { setSessionStorageItem } from '../../../common/storage'
import { AcceptNotify } from './AcceptNotify'
import { CallUsNow } from './CallUsNow'
import { LaunchNotify } from './LaunchNotify'
import { Phone } from './Phone'

export const Residence = ({
  form,
  setCurrentStep,
  signInWithPhoneNumber,
  onlyShowLicensedStates,
  onBack,
}: SignupProps & {
  onlyShowLicensedStates?: boolean
  onBack: () => void
}) => {
  const selectedState = states.find(state => state.abbr === form.values.state)
  const { accountRecoveryReorg } = useFlags()

  useEffect(() => {
    /*
     * NOTE: This effect is here to eagerly submit the state of residence as soon as one is selected.
     * The primary button also calls the submit handler, but only for validation purposes.
     */
    if (selectedState) {
      // Clear the phone number
      form.setFieldValue('phoneNumber', '')
      // Reset the mutation in case it's already been triggered.
      signInWithPhoneNumber.reset()
      // Eagerly submit the new state of residence
      setSessionStorageItem('residence_state', selectedState?.state)
      sendTrackEvent('State Collected In Welcome Flow', { state: selectedState?.state })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState])

  useLifecycle({
    onMount: () => {
      logger.info('State Collection', { tags: { workflow: 'onboarding' } })
      sendPageEvent('State Collection')
    },
  })

  return (
    <Box test-id='content:residence'>
      <TitleTwo>Where do you live?</TitleTwo>
      <Space h='sm' />
      <Text>This helps us pair you with a licensed clinician in your state.</Text>
      <Space h='lg' />
      <Select
        searchable
        clearable
        nothingFound='No options'
        test-id='input:residence'
        placeholder='Search...'
        label='Primary state of residence'
        warning={getStateWarning(selectedState)}
        data={states
          .filter(state => !onlyShowLicensedStates || (state.active && !state.paused))
          .map(state => ({
            value: state.abbr,
            label: state.state,
          }))}
        {...form.getInputProps('state')}
      />
      <Stack spacing='md'>
        <Box>
          {!form.values.state && (
            <ResponsiveBox
              mt='lg'
              mobile={
                <Stack spacing='lg'>
                  <PrimaryButton test-id='button:next@mobile' fullWidth onClick={form.validate}>
                    Next
                  </PrimaryButton>
                  <TertiaryButton
                    test-id='button:back@mobile'
                    fullWidth
                    leftIcon={<ArrowLeftIcon />}
                    onClick={onBack}
                  >
                    Back
                  </TertiaryButton>
                </Stack>
              }
              desktop={
                <Group position='apart'>
                  <TertiaryButton
                    test-id='button:back@desktop'
                    leftIcon={<ArrowLeftIcon />}
                    onClick={onBack}
                  >
                    Back
                  </TertiaryButton>
                  <PrimaryButton test-id='button:next@desktop' onClick={form.validate}>
                    Next
                  </PrimaryButton>
                </Group>
              }
            />
          )}
          {form.values.state && <Divider my='lg' />}
          <PhoneStep
            setCurrentStep={setCurrentStep}
            form={form}
            signInWithPhoneNumber={signInWithPhoneNumber}
            selectedState={selectedState}
          />
        </Box>
        {accountRecoveryReorg && <ReturningToOphelia />}
      </Stack>
    </Box>
  )
}

const getStateWarning = (selectedState: (typeof states)[number] | undefined) => {
  if (!selectedState) {
    return
  }

  if (!selectedState.active) {
    return `We're not licensed in your state yet`
  }

  if (selectedState.paused) {
    return `We'll start accepting new patients in Vermont again soon`
  }
}

const PhoneStep = ({
  setCurrentStep,
  form,
  signInWithPhoneNumber,
  selectedState,
}: { selectedState: (typeof states)[number] | undefined } & SignupProps) => {
  const { callUsNowCta } = useFlags()
  const { isMobile } = usePortalDims()
  const { isQueueOpen } = useCallMeNowQueueStatus()
  const [disableCallUsNow, setDisableCallUsNow] = useState(false)

  if (!selectedState) {
    return null
  }

  if (!selectedState.active) {
    return (
      <LaunchNotify
        setCurrentStep={setCurrentStep}
        form={form}
        signInWithPhoneNumber={signInWithPhoneNumber}
      />
    )
  }

  if (selectedState.paused) {
    return (
      <AcceptNotify
        setCurrentStep={setCurrentStep}
        form={form}
        signInWithPhoneNumber={signInWithPhoneNumber}
      />
    )
  }

  if (callUsNowCta && isMobile && isQueueOpen && !disableCallUsNow) {
    return (
      <CallUsNow
        form={form}
        setCurrentStep={setCurrentStep}
        signInWithPhoneNumber={signInWithPhoneNumber}
        setDisableCallUsNow={setDisableCallUsNow}
      />
    )
  }

  return (
    <Phone
      setCurrentStep={setCurrentStep}
      form={form}
      signInWithPhoneNumber={signInWithPhoneNumber}
    />
  )
}
