import { Flex, Loader } from '@shared/components'
import React from 'react'
import { useFlags, useMyQuery } from '../../common/hooks'
import { analytics } from '../../common/rudderstack'
import { Welcome } from './Welcome'
import { WelcomeVideo } from './WelcomeVideo'

export const WelcomeRoot = () => {
  const { isWelcomeVideoEnabled } = useFlags()

  const anonymousId = analytics.getAnonymousId()

  const cohortQuery = useMyQuery(
    'POST /public/settings/cohort',
    {
      data: {
        anonymousId,
        experimentName: 'welcome-video-experiment',
      },
    },
    {
      /**
       * NOTE:
       * This is disabled until we finish implementing the WelcomeVideo component.
       */
      enabled: isWelcomeVideoEnabled,
      /**
       * Do not allow any default retries.
       * Because this is the landing page, we will not retry the request.
       * Retrying runs the risk of slowing down the initial page load.
       */
      retry: false,
    },
  )
  const cohort = cohortQuery.data?.data.cohort

  if (cohortQuery.isLoading) {
    return (
      <Flex justify='center' p='xl'>
        <Loader />
      </Flex>
    )
  }

  if (cohort === 'test') {
    return <WelcomeVideo />
  }

  return <Welcome />
}
