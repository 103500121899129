import React, { PropsWithChildren } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Skeletons } from '../common/components'
import { useAuth } from '../common/hooks'
import { routes } from '../common/routes'
import { getSessionStorageItem } from '../common/storage'
import * as Pages from './pages'

export const ChatAuth = ({ children }: PropsWithChildren) => {
  const { isLoading, isAuthorized } = useAuth()

  if (isLoading) {
    return <Skeletons />
  }

  if (!isAuthorized) {
    /*
     * An unauthorized user cannot chat
     */
    const chatSessionId = getSessionStorageItem('chat_session_id')

    if (chatSessionId) {
      return <Navigate replace to={`${routes.chat.index}/${chatSessionId}`} />
    }

    return <Navigate replace to={`${routes.onboarding.index}`} />
  }

  return <>{children}</>
}

export const ChatApp = () => {
  return (
    <Routes>
      <Route
        path={routes.chat.children.workflowSessionId}
        element={
          <ChatAuth>
            <Pages.ChatStarted />
          </ChatAuth>
        }
      />
      <Route path='*' element={<NavigateToHome />} />
    </Routes>
  )
}

const NavigateToHome = () => {
  return <Navigate replace to={`${routes.welcome.index}`} />
}
