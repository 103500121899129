import { useForm } from '@mantine/form'
import {
  ArrowLeftIcon,
  Box,
  Checkbox,
  CheckboxGroup,
  Group,
  PrimaryButton,
  ResponsiveBox,
  Stack,
  TertiaryButton,
} from '@shared/components'
import {
  PromptContentSingleSelect as PromptContentSingleSelectType,
  PromptResponseContextKey,
  PromptResponseModel,
} from '@shared/types'
import React from 'react'

type Props = {
  value: PromptResponseContextKey[]
  options: PromptContentSingleSelectType['options']
  onSubmit: (data: PromptResponseModel['payload']) => void
  onBack: (() => void) | undefined
}

type Form = {
  contextKey: PromptResponseContextKey[]
}

export const PromptContentMultipleChoice = (props: Props) => {
  const form = useForm<Form>({
    initialValues: {
      contextKey: props.value,
    },
    validate: {
      contextKey: (value: PromptResponseContextKey[]) => (value?.length ? null : 'Required'),
    },
  })

  const onSubmit = () => {
    if (form.validate().hasErrors) {
      return
    }

    props.onSubmit({ value: { contextKey: form.values.contextKey } })
  }

  return (
    <Box test-id='content:multiple-choice'>
      <CheckboxGroup test-id='checkbox-group' {...form.getInputProps('contextKey')}>
        {props.options.map(option => {
          return (
            <Checkbox
              key={option.contextKey}
              value={option.contextKey}
              label={option.primaryText}
            />
          )
        })}
      </CheckboxGroup>
      <ResponsiveBox
        mt='lg'
        mobile={
          <Stack spacing='lg' justify='center'>
            <PrimaryButton test-id='button:next@mobile' fullWidth onClick={onSubmit}>
              Next
            </PrimaryButton>
            {props.onBack && (
              <TertiaryButton
                test-id='button:back@mobile'
                leftIcon={<ArrowLeftIcon />}
                onClick={props.onBack}
                fullWidth
              >
                Back
              </TertiaryButton>
            )}
          </Stack>
        }
        desktop={
          props.onBack ? (
            <Group position='apart'>
              <TertiaryButton
                test-id='button:back@desktop'
                leftIcon={<ArrowLeftIcon />}
                onClick={props.onBack}
              >
                Back
              </TertiaryButton>
              <PrimaryButton test-id='button:next@desktop' onClick={onSubmit}>
                Next
              </PrimaryButton>
            </Group>
          ) : (
            <PrimaryButton test-id='button:next@desktop' onClick={onSubmit}>
              Next
            </PrimaryButton>
          )
        }
      />
    </Box>
  )
}
