import { useForm } from '@mantine/form'
import { Radio, RadioGroup, Stack, validateWith } from '@shared/components'
import { PromptResponseContextKey } from '@shared/types'
import React from 'react'
import { isRequired } from '../../../forms'
import { useAuth } from '../../../hooks'
import { useWelcomeChatAvailability } from '../../../hooks/use-chat-availability'
import { sendTrackEvent } from '../../../rudderstack'
import { PromptContentNavigation } from '../PromptContentNavigation'
import { WidgetProps } from '../PromptContentWidget'

type Form = {
  careReadiness: PromptResponseContextKey | undefined
}

const READY_TO_START = 'care_readiness_yes' as PromptResponseContextKey
const READY_TO_START_CHAT = 'care_readiness_yes_chat_test' as PromptResponseContextKey
const NOT_READY = 'care_readiness_no' as PromptResponseContextKey
const NOT_READY_CHAT = 'care_readiness_no_chat_test' as PromptResponseContextKey

/**
 * This component is used in v12 and v13 of the welcome flow
 * and branches patients to the chat experiment if it is currently
 * running and they are in the test cohort
 */
export const CareReadinessChat = ({ response, onBack, onSubmit }: WidgetProps) => {
  const { currentUser } = useAuth()
  const { isWelcomeChatAvailable } = useWelcomeChatAvailability()

  const getInitialValue = (): PromptResponseContextKey | undefined => {
    if (response?.value.contextKey === READY_TO_START_CHAT) {
      return READY_TO_START
    }
    if (response?.value.contextKey === NOT_READY_CHAT) {
      return NOT_READY
    }
    return response?.value.contextKey as PromptResponseContextKey | undefined
  }

  const form = useForm<Form>({
    initialValues: {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      careReadiness: getInitialValue(),
    },
    validate: {
      careReadiness: validateWith(isRequired),
    },
  })

  const submitHandler = () => {
    const { hasErrors } = form.validate()

    if (hasErrors) {
      return
    }
    if (!form.values.careReadiness) {
      return
    }

    const shouldBranchToChatTest =
      currentUser?.data?.experiments?.['welcome-chat-cohort-split']?.cohort === 'test' &&
      isWelcomeChatAvailable

    sendTrackEvent('[Welcome Chat] Branching Determined', {
      isExperimentRunning: isWelcomeChatAvailable,
      cohort: currentUser?.data?.experiments?.['welcome-chat-cohort-split']?.cohort,
    })

    if (shouldBranchToChatTest) {
      return onSubmit({
        value: {
          contextKey: `${form.values.careReadiness}_chat_test` as PromptResponseContextKey,
        },
      })
    }

    return onSubmit({
      value: {
        contextKey: form.values.careReadiness,
      },
    })
  }

  return (
    <Stack spacing='lg' test-id='content:care-readiness'>
      <RadioGroup {...form.getInputProps('careReadiness')}>
        <Radio
          test-id='radio:care-readiness:yes'
          label={`I'd like to start as soon as possible`}
          value={READY_TO_START}
        />
        <Radio
          test-id='radio:care-readiness:no'
          label={`I'm not sure, I'd like a little more information first`}
          value={NOT_READY}
        />
      </RadioGroup>
      <PromptContentNavigation onBack={onBack} onSubmit={submitHandler} />
    </Stack>
  )
}
