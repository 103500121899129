import { isEmail, useForm } from '@mantine/form'
import {
  DateInput,
  Grid,
  PhoneInput,
  Select,
  Stack,
  Text,
  TextInput,
  validateWith,
} from '@shared/components'
import { StateName, activeStates, stateNames } from '@shared/types'
import { validCharacters } from '@shared/utils'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { isBirthday, isDate, isPhone, isRequired, isTodayOrLater } from '../../../../forms'
import { useFlags } from '../../../../hooks'
import { sendTrackEvent } from '../../../../rudderstack'
import { getSessionStorageItem, setSessionStorageItem } from '../../../../storage'
import { PromptContentNavigation } from '../../PromptContentNavigation'
import { WidgetProps } from '../../PromptContentWidget'
import { WorkflowParams } from '../../WorkflowPrompt'

type CorrectionalFacilityFormType = {
  first_name: string
  last_name: string
  birthday: string
  release_date: string
  state: string
  gender: string
  email: string
  phone: string
}

export const CorrectionalFacilityForm = ({ onBack, onSubmit }: WidgetProps) => {
  const { sessionId } = useParams<WorkflowParams>()
  const { correctionsInpatientForAllStates } = useFlags()
  const VALID_STATE = 'Pennsylvania'

  // Currently, we accept correctional referrals from all active states
  const VALID_CORRECTIONAL_FACILITY_STATES = correctionsInpatientForAllStates
    ? activeStates.map(state => state.state)
    : VALID_STATE

  const reorderedStateNames = [
    VALID_STATE,
    ...stateNames.filter(state => !VALID_CORRECTIONAL_FACILITY_STATES.includes(state)),
  ]

  const stateOptions = correctionsInpatientForAllStates ? stateNames : reorderedStateNames

  const referralType = getSessionStorageItem('referral_workflow_type')

  const form = useForm<CorrectionalFacilityFormType>({
    initialValues: {
      // The following initial values should be blank to protect PHI
      first_name: '',
      last_name: '',
      birthday: '',
      release_date: '',
      state: '',
      gender: '',
      email: '',
      phone: '',
    },
    validate: {
      first_name: validateWith(isRequired),
      last_name: validateWith(isRequired),
      birthday: validateWith(isRequired, isBirthday),
      release_date: validateWith(isRequired, isDate, isTodayOrLater),
      state: validateWith(isRequired),
      gender: validateWith(isRequired),
      email: validateWith(isRequired, isEmail),
      phone: validateWith(isRequired, isPhone),
    },
  })

  const isSelectedStateValid =
    (form.values.state as StateName) &&
    VALID_CORRECTIONAL_FACILITY_STATES.includes(form.values.state as StateName)

  const submitHandler = () => {
    if (form.validate().hasErrors) {
      return
    }

    setSessionStorageItem('referral_workflow_state', form.values.state)
    setSessionStorageItem('referral_workflow_date', form.values.release_date)

    onSubmit({
      value: {
        contextKey: 'custom',
      },
      referral_first_name: {
        contextKey: 'custom',
        value: form.values.first_name,
      },
      referral_last_name: {
        contextKey: 'custom',
        value: form.values.last_name,
      },
      referral_birthday: {
        contextKey: 'custom',
        value: form.values.birthday,
      },
      referral_release_date: {
        contextKey: 'custom',
        value: form.values.release_date,
      },
      referral_state: {
        contextKey: 'custom',
        value: form.values.state,
      },
      referral_gender: {
        contextKey: 'custom',
        value: form.values.gender,
      },
      referral_email: {
        contextKey: 'custom',
        value: form.values.email,
      },
      referral_phone: {
        contextKey: 'custom',
        value: form.values.phone,
      },
    })
  }

  useEffect(() => {
    if (
      (form.values.state as StateName) &&
      !VALID_CORRECTIONAL_FACILITY_STATES.includes(form.values.state as StateName)
    ) {
      // Keep track of when users select a state other than Pennsylvania
      sendTrackEvent('Corrections Flow State Selected', { state: form.values.state, sessionId })
    }
  }, [form.values.state])

  return (
    <Stack spacing='md' test-id='content:correctional-facility-form'>
      <Grid>
        <Grid.Col span={12} md={6}>
          <TextInput
            test-id='input:referral-first-name'
            placeholder={`Referral's first name`}
            label={`Referral's first name`}
            formatter={validCharacters.name}
            maxLength={64}
            {...form.getInputProps('first_name')}
          />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <TextInput
            test-id='input:referral-last-name'
            placeholder={`Referral's last name`}
            label={`Referral's last name`}
            formatter={validCharacters.name}
            maxLength={64}
            {...form.getInputProps('last_name')}
          />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <Select
            test-id='input:referral-state'
            searchable
            clearable
            label={`Referral's state of residence`}
            placeholder='Select one...'
            data={stateOptions}
            {...form.getInputProps('state')}
            warning={
              form.values.state && !isSelectedStateValid
                ? `Unfortunately, we're not accepting correctional referrals in your state yet`
                : ''
            }
          />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <Select
            test-id='input:referral-gender'
            {...form.getInputProps('gender')}
            placeholder='Select one...'
            label={`Referral's gender`}
            data={[
              { value: 'male', label: 'Male' },
              { value: 'female', label: 'Female' },
              { value: 'other', label: 'Other' },
              { value: 'not_given', label: 'Not given' },
            ]}
          />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <DateInput
            test-id='input:referral-birthday'
            label={`Referral's birthday`}
            {...form.getInputProps('birthday')}
          />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <DateInput
            test-id='input:referral-release-date'
            label={`Estimated date of ${
              referralType === 'type_of_referral_correctional_facility' ? 'release' : 'discharge'
            }`}
            {...form.getInputProps('release_date')}
          />
        </Grid.Col>
      </Grid>
      <Text>
        {`Please provide a cell phone number and email address that the patient will have access to
        upon release. We will reach out to coordinate ahead of their intake visit based on the
        estimated date of ${
          referralType === 'type_of_referral_correctional_facility' ? 'release' : 'discharge'
        }`}
        .
      </Text>
      <TextInput
        test-id='input:referral-email'
        placeholder={`Referral's email`}
        label={`Referral's email`}
        type='email'
        maxLength={64}
        {...form.getInputProps('email')}
      />
      <PhoneInput
        test-id='input:referral-phone'
        label={`Referral's phone`}
        {...form.getInputProps('phone')}
      />
      <PromptContentNavigation
        onBack={onBack}
        onSubmit={submitHandler}
        disableSubmit={Boolean(form.values.state && !isSelectedStateValid)}
      />
    </Stack>
  )
}
