import { useForm } from '@mantine/form'
import { Radio, RadioGroup, Stack, Text, TextInput } from '@shared/components'
import { PromptResponseContextKey, states } from '@shared/types'
import { isCityOrStateKnown } from '@shared/utils'
import React from 'react'
import { CityStateForm, CityStateSelectGroup } from '../../CityStateSelectGroup'
import { PromptContentNavigation } from '../PromptContentNavigation'
import { WidgetProps } from '../PromptContentWidget'

export type WhoReferredYouForm = {
  referred_by_healthcare_provider: PromptResponseContextKey | undefined
  who_referred_you: string
} & CityStateForm

const REFERRED_YES: PromptResponseContextKey = 'referred_by_healthcare_provider_positive'
const REFERRED_NO: PromptResponseContextKey = 'referred_by_healthcare_provider_negative'

export const WhoReferredYou = ({ response, onBack, onSubmit }: WidgetProps) => {
  const form = useForm<WhoReferredYouForm>({
    initialValues: {
      referred_by_healthcare_provider: response?.value.contextKey as
        | PromptResponseContextKey
        | undefined,
      who_referred_you: response?.who_referred_you?.value as string,
      state: response?.who_referred_you_state?.value as string,
      city: response?.who_referred_you_city?.value as string,
    },
    validate: values => {
      const requireWhoReferredYou = values.referred_by_healthcare_provider === REFERRED_YES

      if (requireWhoReferredYou) {
        const isCityRequired = isCityOrStateKnown(values.state)
        if (isCityRequired) {
          return {
            referred_by_healthcare_provider: values.referred_by_healthcare_provider
              ? null
              : 'Please select one',
            who_referred_you: values.who_referred_you ? null : 'Required',
            state: values.state ? null : 'Required',
            city: values.city ? null : 'Required',
          }
        }

        return {
          referred_by_healthcare_provider: values.referred_by_healthcare_provider
            ? null
            : 'Please select one',
          who_referred_you: values.who_referred_you ? null : 'Required',
          state: values.state ? null : 'Required',
        }
      }

      // In this scenario, the user selects no, therefore there is no need to provide a referral.
      return {
        referred_by_healthcare_provider: values.referred_by_healthcare_provider
          ? null
          : 'Please select one',
        who_referred_you: null,
      }
    },
  })

  const submitHandler = () => {
    const { hasErrors } = form.validate()

    if (hasErrors) {
      return
    }
    if (!form.values.referred_by_healthcare_provider) {
      return
    }

    if (form.values.referred_by_healthcare_provider === REFERRED_YES) {
      onSubmit({
        value: {
          contextKey: form.values.referred_by_healthcare_provider,
        },
        who_referred_you: {
          contextKey: 'custom',
          value: form.values.who_referred_you,
        },
        who_referred_you_state: {
          contextKey: 'custom',
          value: form.values.state,
        },
        who_referred_you_city: {
          contextKey: 'custom',
          value: form.values.city,
        },
      })
    } else {
      onSubmit({
        value: {
          contextKey: form.values.referred_by_healthcare_provider,
        },
      })
    }
  }

  return (
    <Stack spacing='lg' test-id='content:referred-by-healthcare-provider'>
      <Text>
        This could be a healthcare provider, treatment facility, social services organization, or
        any other type of organization.
      </Text>
      <Text>Your answer has no impact on your ability to receive treatment.</Text>
      <RadioGroup
        test-id='radio-group:referred-by-healthcare-provider'
        {...form.getInputProps('referred_by_healthcare_provider')}
      >
        <Radio value={REFERRED_YES} label='Yes' />
        <Radio value={REFERRED_NO} label='No' />
      </RadioGroup>
      {form.values.referred_by_healthcare_provider === REFERRED_YES && (
        <Stack>
          <TextInput
            test-id='input:which-healthcare-provider'
            placeholder='E.g. Dr. John Doe or Example Medical Center'
            label={`Which organization or facility referred you? If you don't know, what is the name of the person that referred you?`}
            {...form.getInputProps('who_referred_you')}
          />
          <CityStateSelectGroup
            states={states}
            inputProps={form.getInputProps}
            cityLabel='Which city is the healthcare provider or hospital located in?'
            stateLabel='Which state is the healthcare provider or hospital located in?'
          />
        </Stack>
      )}
      <PromptContentNavigation onBack={onBack} onSubmit={submitHandler} />
    </Stack>
  )
}
