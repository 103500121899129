import { useForm } from '@mantine/form'
import {
  ArrowLeftIcon,
  Checkbox,
  CheckboxGroup,
  Group,
  PrimaryButton,
  ResponsiveBox,
  Stack,
  TertiaryButton,
  TextInput,
  TitleTwo,
  skipIfOtherField,
  validateWith,
} from '@shared/components'
import { states } from '@shared/types'
import { CITY_STATE_OTHER, CITY_STATE_UNKNOWN } from '@shared/utils'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PartnerLogo } from '../../../../welcome/pages/PartnerLogo'
import { isAtleastOneWord, isEmail, isRequired } from '../../../forms'
import { useReferralPartner } from '../../../hooks/use-partner'
import { setSessionStorageItem } from '../../../storage'
import { CityStateForm, CityStateSelectGroup } from '../../CityStateSelectGroup'
import { WidgetProps } from '../PromptContentWidget'

enum PartnershipInterestValues {
  Interest = 'interest',
}

export type PartnerOnboardingForm = {
  name: string
  email: string
  role: string
  partnershipInterest: string[]
} & CityStateForm

export const ReferringProviderWithPatientContactForm = ({ response, onSubmit }: WidgetProps) => {
  const navigate = useNavigate()
  const onBack = () => navigate(-1)
  const { sessionId, promptId } = useParams()
  const { partner } = useReferralPartner()

  const form = useForm<PartnerOnboardingForm>({
    validateInputOnBlur: ['name', 'email', 'role'],
    initialValues: {
      name: response?.provider_full_name?.value as string,
      email: response?.provider_email?.value as string,
      role: response?.provider_role?.value as string,
      state: response?.provider_state?.value as string,
      city: response?.provider_city?.value as string,
      partnershipInterest: response?.partnership_interest?.value
        ? [PartnershipInterestValues.Interest]
        : [],
    },
    validate: {
      name: validateWith(isRequired, isAtleastOneWord),
      email: validateWith(isRequired, isEmail),
      role: validateWith(isRequired, isAtleastOneWord),
      state: validateWith(isRequired),
      city: validateWith(
        skipIfOtherField('state', 'is', CITY_STATE_OTHER),
        skipIfOtherField('state', 'is', CITY_STATE_UNKNOWN),
        isRequired,
      ),
    },
  })

  const submitHandler = () => {
    if (form.validate().hasErrors) {
      return
    }

    setSessionStorageItem('referral_workflow_prompt_response', `${sessionId}_${promptId}`)

    onSubmit({
      value: {
        contextKey: 'custom',
      },
      provider_organization: {
        contextKey: 'custom',
        value: partner?.oid || '',
      },
      provider_full_name: {
        contextKey: 'custom',
        value: form.values.name,
      },
      provider_email: {
        contextKey: 'custom',
        value: form.values.email,
      },
      provider_role: {
        contextKey: 'custom',
        value: form.values.role,
      },
      partnership_interest: {
        contextKey: 'custom',
        value: form.values.partnershipInterest.includes(PartnershipInterestValues.Interest),
      },
      provider_state: {
        contextKey: 'custom',
        value: form.values.state,
      },
      provider_city: {
        contextKey: 'custom',
        value: form.values.city,
      },
    })
  }

  return (
    <Stack spacing='md' test-id='page:partner-with-patient-contact-form'>
      <PartnerLogo partner={partner} />
      <TitleTwo>These questions are intended to be answered by the healthcare provider</TitleTwo>
      <Stack spacing='md'>
        <TextInput
          test-id='input:full-name'
          placeholder='Full name'
          label='Full name'
          {...form.getInputProps('name')}
        ></TextInput>
        <TextInput
          test-id='input:email'
          type='email'
          placeholder='Work email'
          label='Work email'
          {...form.getInputProps('email')}
        ></TextInput>
        <TextInput
          test-id='input:role'
          placeholder='Role/title'
          label='Role/title'
          {...form.getInputProps('role')}
        ></TextInput>
        <CityStateSelectGroup states={states} inputProps={form.getInputProps} />
        <CheckboxGroup {...form.getInputProps('partnershipInterest')}>
          <Checkbox
            value={PartnershipInterestValues.Interest}
            label={`I'm interested in partnering with Ophelia to refer more patients`}
          />
        </CheckboxGroup>
      </Stack>
      <ResponsiveBox
        mt='lg'
        mobile={
          <Stack spacing='lg' align='center'>
            <PrimaryButton test-id='button:next@mobile' fullWidth onClick={submitHandler}>
              Next
            </PrimaryButton>
            <TertiaryButton
              test-id='button:back@mobile'
              leftIcon={<ArrowLeftIcon />}
              onClick={onBack}
            >
              Back
            </TertiaryButton>
          </Stack>
        }
        desktop={
          <Group position='apart'>
            <TertiaryButton
              test-id='button:back@desktop'
              leftIcon={<ArrowLeftIcon />}
              onClick={onBack}
            >
              Back
            </TertiaryButton>
            <Group position='right'>
              <PrimaryButton test-id='button:next@desktop' onClick={submitHandler}>
                Next
              </PrimaryButton>
            </Group>
          </Group>
        }
      />
    </Stack>
  )
}
