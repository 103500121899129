const WELCOME_CALL_PROMPT_CONTEXT_KEYS = [
  'opioid_use_description',
  'opioid_use_history',
  'opioid_use_frequency',
  'opioid_use_frequency_value_props',
  'suboxone_use_history',
  'payment_method',
  'suboxone_use_last_week',
  'heroin_or_fentanyl_use_regular',
  'how_did_you_hear_about_us',
  'how_did_you_hear_about_us_which_conference',
  'how_did_you_hear_about_us_share_helpful_info',
  'how_did_you_hear_about_us_provider_name',
  'how_did_you_hear_about_us_provider_org',
  'how_did_you_hear_about_us_other',
  'insurance_provider',
  'only_treat_opioid_use',
  'schedule_welcome_call',
  'schedule_welcome_call_type',
  'schedule_welcome_call_reconnect',
  'schedule_welcome_call_first_name',
  'schedule_welcome_call_last_name',
  'schedule_welcome_call_phone',
  'schedule_welcome_call_ext',
  'schedule_welcome_call_timezone',
  'schedule_welcome_call_address_state',
  'schedule_intake_visit_address_state',
  'start_welcome_chat_type',
  'start_welcome_chat_reconnect',
  'start_welcome_chat_first_name',
  'start_welcome_chat_last_name',
  'start_welcome_chat_timezone',
  'start_welcome_chat_address_state',
  'start_welcome_chat_appointment_id',
  'patient_address_state',
  'care_transfer_cohort',
  'schedule_intake_visit_calendar',
  'schedule_intake_visit_first_name',
  'schedule_intake_visit_last_name',
  'schedule_intake_visit_phone',
  'schedule_intake_visit_timezone',
  'schedule_intake_visit_early_notification_interest',
  'suboxone_prescribed_by',
  'suboxone_taken_duration',
  'suboxone_daily_dose_stablity',
  'suboxone_subscription_days_left',
  'referred_by_healthcare_provider',
  'social_determinants_of_health',
  'welcome_call_explanation',
  'who_referred_you',
  'who_referred_you_city',
  'who_referred_you_state',
  'motivations_for_treatment_form',
  'motivations_description',
  'care_readiness',
  /**
   * @welcomeCallStandbyExperiment
   * Experiment started on 5/9/24 to allow patients who
   * schedule welcome calls for later to indicate whether
   * they would like to be notified of earlier appointments
   */
  'schedule_welcome_call_early_notification_interest',
  // v12 start
  'suboxone_prescribed_by_care_transfer',
  'reason_for_new_provider',
  'how_to_get_started',
  'payment_method_intake_visit',
  'insurance_verification',
  'schedule_intake_visit',
  'schedule_welcome_call_care_transfer',
  'cardholder_birthday',
  'cardholder_first_name',
  'cardholder_last_name',
  'cardholder_member_id',
  'insurance_provider_care_transfer',
  // v13 start
  'how_to_connect',
  'start_welcome_chat',
  // v14
  'not_alone',
  'what_comes_next',
] as const

export type WelcomeCallPromptContextKey = (typeof WELCOME_CALL_PROMPT_CONTEXT_KEYS)[number]

const SCHEDULE_WELCOME_CALL_TYPES = [
  'chat_now',
  'call_me_now',
  'call_me_later',
  'inbound_caller_call_me_now',
] as const

const START_WELCOME_CHAT_CONTEXT_KEYS = ['chat_now'] as const

/** Possible responses for current opioid use prompt */

const OPIOID_USE_DESCRIPTION_RESPONSE_CONTEXT_KEYS = [
  // deprecated
  'opioid_use_description_not_under_control',
  // deprecated
  'opioid_use_description_under_control',
  'opioid_use_description_never_used',
  'opioid_use_description_not_currently_using',
  'opioid_use_description_currently_using',
] as const

/** Possible responses for historical opioid use prompt */
const OPIOID_USE_HISTORY_RESPONSE_CONTEXT_KEYS = [
  'opioid_use_history_not_currently_using',
  'opioid_use_history_less_than_six_months',
  'opioid_use_history_six_to_eleven_months',
  'opioid_use_history_one_to_five_years',
  'opioid_use_history_six_to_ten_years',
  'opioid_use_history_more_than_ten_years',
] as const

/** Possible responses for opioid use frequency prompt */
const OPIOID_USE_FREQUENCY_RESPONSE_CONTEXT_KEYS = [
  'opioid_use_frequency_daily',
  'opioid_use_frequency_several_times_per_week',
  'opioid_use_frequency_several_times_per_month',
  'opioid_use_frequency_not_currently_using',
] as const

/** Possible responses for historical suboxone use prompt */
const SUBOXONE_USE_HISTORY_RESPONSE_CONTEXT_KEYS = [
  'suboxone_use_history_currently_taking',
  'suboxone_use_history_taken_in_past',
  'suboxone_use_history_never_taken',
] as const

/** Possible responses for intended payment method prompt */
const PAYMENT_METHOD_RESPONSE_CONTEXT_KEYS = [
  'payment_method_cash',
  'payment_method_insurance',
  'payment_method_insurance_other',
  'insurance_provider',
  'insurance_provider_multiplan',
  'insurance_provider_humana_behavioral_health',
  'insurance_provider_anthem_ct',
  'insurance_provider_united_mine_workers_assoc_retiree_fund',
  'insurance_provider_other',
] as const

const INSURANCE_VERIFICATION_CONTEXT_KEYS = ['no_insurance_info_available'] as const

const INTAKE_BOOKING_CONTEXT_KEYS = ['need_to_book_further_out'] as const

const SCHEDULE_WELCOME_CALL_RESPONSE_CONTEXT_KEY = 'call_me_now' as const

const SUBOXONE_USE_LAST_WEEK_CONTEXT_KEYS = [
  'suboxone_taken_last_week',
  'suboxone_not_taken_last_week',
] as const

const HEROIN_OR_FENTANYL_USE_CONTEXT_KEYS = [
  'heroin_or_fentanyl_use_regular_no',
  'heroin_or_fentanyl_use_regular_yes',
] as const

const IS_SUBOXONE_PRESCRIBED_CONTEXT_KEYS = [
  'suboxone_prescribed_by_medical_professional_care_transfer',
  'suboxone_prescribed_by_medical_professional',
  'suboxone_prescribed_by_none',
] as const

const SUBOXONE_TAKEN_DURATION_CONTEXT_KEYS = [
  'suboxone_taken_duration_less_than_3_months',
  'suboxone_taken_duration_3_to_6_months',
  'suboxone_taken_duration_more_than_6_months',
] as const

const IS_STABLE_WITH_DAILY_SUBOXONE_DOSE = [
  'suboxone_daily_dose_stablity_positive',
  'suboxone_daily_dose_stablity_negative',
] as const

const REFERRED_BY_HEALTH_CARE_PROVIDER = [
  'referred_by_healthcare_provider',
  'referred_by_healthcare_provider_positive',
  'referred_by_healthcare_provider_negative',
  'who_referred_you',
  'who_referred_you_city',
  'who_referred_you_state',
] as const

const SOCIAL_DETERMINANTS_OF_HEALTH = [
  'social_determinants_of_health',
  'stable_housing_access',
  'paying_bills',
  'reliable_transportation',
  'abuse_or_violence_in_relationships',
  'criminal_justice_involvement',
  'safety_concerns',
  'social_determinants_none',
  'social_determinants_of_health_care_transfer',
] as const

const CARE_READINESS_CONTEXT_KEYS = [
  'care_readiness_yes',
  'care_readiness_no',
  'care_readiness_yes_chat_test',
  'care_readiness_no_chat_test',
] as const

const WHAT_COMES_NEXT_CONTEXT_KEYS = ['what_comes_next', 'what_comes_next_chat_test'] as const

const HOW_TO_GET_STARTED_CONTEXT_KEYS = [
  'get_started_with_welcome_call',
  'get_started_with_intake_visit',
  'interested_in_subutex',
] as const

const HOW_TO_CONNECT_CONTEXT_KEYS = ['connect_via_chat', 'connect_via_call'] as const

export const WELCOME_CALL_PROMPT_RESPONSE_CONTEXT_KEYS = [
  ...OPIOID_USE_DESCRIPTION_RESPONSE_CONTEXT_KEYS,
  ...OPIOID_USE_HISTORY_RESPONSE_CONTEXT_KEYS,
  ...OPIOID_USE_FREQUENCY_RESPONSE_CONTEXT_KEYS,
  ...SUBOXONE_USE_HISTORY_RESPONSE_CONTEXT_KEYS,
  ...PAYMENT_METHOD_RESPONSE_CONTEXT_KEYS,
  SCHEDULE_WELCOME_CALL_RESPONSE_CONTEXT_KEY,
  ...SUBOXONE_USE_LAST_WEEK_CONTEXT_KEYS,
  ...HEROIN_OR_FENTANYL_USE_CONTEXT_KEYS,
  ...SCHEDULE_WELCOME_CALL_TYPES,
  ...IS_SUBOXONE_PRESCRIBED_CONTEXT_KEYS,
  ...SUBOXONE_TAKEN_DURATION_CONTEXT_KEYS,
  ...IS_STABLE_WITH_DAILY_SUBOXONE_DOSE,
  ...REFERRED_BY_HEALTH_CARE_PROVIDER,
  ...SOCIAL_DETERMINANTS_OF_HEALTH,
  ...CARE_READINESS_CONTEXT_KEYS,
  ...HOW_TO_GET_STARTED_CONTEXT_KEYS,
  ...INSURANCE_VERIFICATION_CONTEXT_KEYS,
  ...INTAKE_BOOKING_CONTEXT_KEYS,
  ...HOW_TO_CONNECT_CONTEXT_KEYS,
  ...START_WELCOME_CHAT_CONTEXT_KEYS,
  ...WHAT_COMES_NEXT_CONTEXT_KEYS,
  'custom',
] as const

export type WelcomeCallPromptResponseContextKey =
  (typeof WELCOME_CALL_PROMPT_RESPONSE_CONTEXT_KEYS)[number]
