import { StateAbbr } from './api'

export type ReferralOption =
  | 'provider'
  | 'chase_list'
  | 'health_plan_outreach'
  | 'correctional_facility'
  | 'friend'
  | 'family'
  | 'spouse_partner'
  | 'colleague'
  | 'partner'
  | 'online'
  | 'youtube'
  | 'social_media'
  | 'news_article'
  | 'direct_mail'
  | 'conference'
  | 'billboard'
  | 'radio'
  | 'television'
  | 'flyer'
  | 'other'
  | 'no_answer'

export type ReferralSource =
  | 'welcome_call'
  | 'welcome_flow'
  | 'portal_task'
  | 'referral_flow'
  | 'portal_appointment_confirmation'
  | 'portal_refer_friends'
  | 'portal_refer_friends_popup'
  | 'zoom_redirect'
  | 'emr_patient_chart'

type ReferralInitialStatus = 'pending' | 'sent' | 'scheduled'

export type ReferralStatus = ReferralInitialStatus | 'lead' | 'conversion'

export type ReferringProvider = {
  fullName?: string
  email: string
  phone?: string
  organization: string
  partnershipInterest?: boolean
  state: StateAbbr
  city: string
}

export type ReferralContact = {
  email: string
  availableDate: string
  acknowledgement: boolean
  phone?: string
  firstName?: string
  referralType?: string
}

export type ReferralTracking = {
  createdAt?: string
  referralFlowSessionId?: string
  welcomeFlowSessionId?: string
  referrerPatientId?: string
  status?: ReferralStatus
  provider?: string | ReferringProvider
  contact?: ReferralContact
  organization?: string
  additional?: string
  springHealth?: SpringHealthData
  city?: string
  state?: string
}

export type SpringHealthData = {
  springHealthId?: string
  isBilledBySpringHealth?: boolean
}

export const HDYHAUOptions: { value: ReferralOption; label: string }[] = [
  {
    value: 'provider',
    label: 'A health care provider referred me',
  },
  {
    value: 'friend',
    label: 'A family or friend or someone I know',
  },
  {
    value: 'health_plan_outreach',
    label: 'Email, call, or mail from Ophelia or a health plan',
  },
  {
    value: 'online',
    label: 'Search engine',
  },
  {
    value: 'youtube',
    label: 'YouTube',
  },
  {
    value: 'social_media',
    label: 'Social media',
  },
  {
    value: 'news_article',
    label: 'News article/press release',
  },
  {
    value: 'direct_mail',
    label: 'Direct mail',
  },
  {
    value: 'conference',
    label: 'At a conference',
  },
  {
    value: 'billboard',
    label: 'Billboard',
  },
  {
    value: 'radio',
    label: 'Radio',
  },
  {
    value: 'television',
    label: 'T.V.',
  },
  {
    value: 'flyer',
    label: 'Informational flyer',
  },
  {
    value: 'other',
    label: 'Other',
  },
  {
    value: 'no_answer',
    label: 'Prefer not to answer',
  },
]

export type ReferralEventTrigger =
  | 'created'
  | 'link_clicked'
  | 'status_changed'
  | 'new_patient_associated'

export type ReferralEventContext = {
  patientId?: string
  fromStatus?: ReferralStatus
  toStatus?: ReferralStatus
}

export const REFERRAL_PARAM = 'r'

// Fake temporary phone number of corrections referrals
export const CORRECTIONAL_FACILITY_PHONE = '+15555555555'
