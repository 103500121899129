import { useForm } from '@mantine/form'
import {
  CalendarIcon,
  CreditCardIcon,
  HelpCircleIcon,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Space,
  Stack,
  Text,
  TitleTwo,
  validateWith,
} from '@shared/components'
import { PromptResponseContextKey } from '@shared/types'
import React from 'react'
import { isRequired } from '../../../forms'
import { PromptContentNavigation } from '../PromptContentNavigation'
import { WidgetProps } from '../PromptContentWidget'

type Form = {
  howToConnect: PromptResponseContextKey | undefined
}

const CHAT = 'connect_via_chat' as PromptResponseContextKey
const CALL = 'connect_via_call' as PromptResponseContextKey
export const HowToConnect = ({ response, onBack, onSubmit }: WidgetProps) => {
  const form = useForm<Form>({
    initialValues: {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      howToConnect: response?.value.contextKey as PromptResponseContextKey | undefined,
    },
    validate: {
      howToConnect: validateWith(isRequired),
    },
  })

  const submitHandler = () => {
    const { hasErrors } = form.validate()

    if (hasErrors) {
      return
    }
    if (!form.values.howToConnect) {
      return
    }

    onSubmit({
      value: {
        contextKey: form.values.howToConnect,
      },
    })
  }

  return (
    <Stack spacing='md' test-id='content:how-to-connect'>
      <Stack spacing='xs'>
        <TitleTwo>Great, now lets get you set up for care</TitleTwo>
        <Text>To complete enrollment, please connect with our team who can help:</Text>
      </Stack>
      <Stack spacing='sm'>
        <Space h='xs' />
        <List
          spacing='sm'
          sx={() => ({ paddingLeft: 0 })}
          styles={theme => ({ itemIcon: { color: theme.other.colors.text[1] } })}
        >
          <ListItem icon={<CalendarIcon />}>Schedule your first clinical visit</ListItem>
          <ListItem icon={<CreditCardIcon />}>Explain insurance & payment options</ListItem>
          <ListItem icon={<HelpCircleIcon />}>Answer all your questions</ListItem>
        </List>
        <Space h='xs' />
        <Stack spacing='xs'>
          <Text>How would you like to connect? </Text>
          <Text size='xs'>It&apos;ll only take 10-15 minutes</Text>
        </Stack>
      </Stack>
      <RadioGroup {...form.getInputProps('howToConnect')}>
        <Radio
          test-id='radio:connect-by:call'
          label='Phone call'
          value={CALL}
          description="We'll reach out to complete enrollment over the phone"
        />
        <Radio
          test-id='radio:connect-by:chat'
          label='Live chat'
          value={CHAT}
          description='Enroll through live chat—right in your browser.'
        />
      </RadioGroup>
      <PromptContentNavigation onBack={onBack} onSubmit={submitHandler} />
    </Stack>
  )
}
