import {
  ArrowLeftIcon,
  CheckIcon,
  ChevronRightIcon,
  GlobeIcon,
  Group,
  List,
  ListItem,
  PrimaryButton,
  ResponsiveBox,
  SmartphoneIcon,
  Space,
  Stack,
  TertiaryButton,
  Text,
  TitleTwo,
  emojiMap,
  useLifecycle,
} from '@shared/components'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getSessionStorageItem, removeSessionStorageItem } from '../../../storage'
import { WidgetProps } from '../PromptContentWidget'

export const ReferSomeone = ({ onSubmit }: WidgetProps) => {
  const navigate = useNavigate()
  const back = () => navigate(-1)

  const submitHandler = () => {
    onSubmit({
      value: {
        contextKey: 'custom',
        value: 'refer_someone',
      },
    })
  }

  useLifecycle({
    onMount: () => {
      const provider = getSessionStorageItem('referral_workflow_prompt_response')
      if (provider) {
        removeSessionStorageItem('referral_workflow_prompt_response')
      }
    },
  })

  return (
    <Stack spacing='md'>
      <Stack spacing='xs'>
        <TitleTwo>{`${emojiMap.handshake} We look forward to supporting your referral`}</TitleTwo>
        <Text>Any information submitted here will be kept secure and confidential.</Text>
      </Stack>

      <Stack spacing='sm'>
        <Text>We’re best suited to support:</Text>
        <Space h='xs' />
        <List
          spacing='sm'
          sx={() => ({ paddingLeft: 0 })}
          styles={theme => ({ itemIcon: { color: theme.other.colors.text[1] } })}
        >
          <ListItem icon={<CheckIcon />}>People with opioid use disorder</ListItem>
          <ListItem icon={<SmartphoneIcon />}>People who have access to a cell phone</ListItem>
          <ListItem icon={<GlobeIcon />}>People who have access to the internet</ListItem>
        </List>
      </Stack>

      <ResponsiveBox
        mt='lg'
        mobile={
          <Stack spacing='lg' justify='center'>
            <PrimaryButton fullWidth rightIcon={<ChevronRightIcon />} onClick={submitHandler}>
              Refer someone to Ophelia
            </PrimaryButton>
            <TertiaryButton
              test-id='button:back@mobile'
              leftIcon={<ArrowLeftIcon />}
              style={{ margin: 'auto' }}
              onClick={back}
            >
              Back
            </TertiaryButton>
          </Stack>
        }
        desktop={
          <Group position='apart'>
            <TertiaryButton
              test-id='button:back@desktop'
              leftIcon={<ArrowLeftIcon />}
              onClick={back}
            >
              Back
            </TertiaryButton>
            <PrimaryButton rightIcon={<ChevronRightIcon />} onClick={submitHandler}>
              Refer someone to Ophelia
            </PrimaryButton>
          </Group>
        }
      />
    </Stack>
  )
}
