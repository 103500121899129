import {
  Box,
  CheckCircleIcon,
  Divider,
  Group,
  Pill,
  Stack,
  Text,
  TitleTwo,
} from '@shared/components'
import React from 'react'
import { PromptContentNavigation } from '../../../common/components/prompt/PromptContentNavigation'

export const GettingStartedBox = ({
  title,
  description,
  timeline,
  isActiveStep,
  isCompletedStep = false,
}: {
  title: string
  description: string
  timeline: string
  isActiveStep: boolean
  isCompletedStep?: boolean
}) => {
  if (isCompletedStep) {
    return (
      <Box
        pl='sm'
        sx={({ radius, other: { colors, sizes } }) => ({
          border: `${sizes.border.md} solid ${colors.background[1]}`,
          borderRadius: radius.sm,
        })}
      >
        <Group position='left'>
          <CheckCircleIcon color={colors => colors.background[3]} />
          <Text p='sm' bold color={colors => colors.background[3]}>
            {title}
          </Text>
        </Group>
      </Box>
    )
  }

  return (
    <Box
      sx={({ radius, other: { colors, sizes } }) => ({
        border: `${sizes.border.md} solid ${
          isActiveStep ? colors.background[2] : colors.background[1]
        }`,
        borderRadius: radius.sm,
      })}
    >
      <Stack spacing={0}>
        <Group position='apart'>
          <Text
            p='sm'
            bold
            color={colors => (isActiveStep ? colors.actions[0] : colors.background[3])}
          >
            {title}
          </Text>
          {isActiveStep && (
            <Pill variant='outline' status='success' mr='sm'>
              Now
            </Pill>
          )}
        </Group>
        <Divider size='lg' color={colors => colors.background[1]} sx={{ width: '100%' }} />
        <Stack spacing='sm' p='sm'>
          <Text color={colors => (isActiveStep ? 'default' : colors.background[3])}>
            {description}
          </Text>
          <Text
            size='xs'
            color={colors => (isActiveStep ? colors.success[0] : colors.background[3])}
            bold
          >
            {timeline}
          </Text>
        </Stack>
      </Stack>
    </Box>
  )
}

export const ValuePropsGettingStarted = ({
  onNext,
  onBack,
}: {
  onNext: () => void
  onBack: () => void
}) => {
  return (
    <Box test-id='page:value-props-offers'>
      <Stack spacing='md'>
        <TitleTwo>Getting started is quick, easy and painless.</TitleTwo>
        <Stack spacing='sm'>
          <GettingStartedBox
            title='Online assessment'
            description='Answer questions about your experience with OUD and Suboxone so we can personalize your care.'
            timeline='~ 5 mins'
            isActiveStep
          />
          <GettingStartedBox
            title='Complete enrollment'
            description='Our enrollment team will go over cost, verify insurance and schedule your first visit.'
            timeline='Within 24 hours*'
            isActiveStep={false}
          />
          <GettingStartedBox
            title='Meet clinician over Zoom'
            description='If we’re a fit, you will get a prescription and a personalized treatment plan.'
            timeline='Within 2-3 days*'
            isActiveStep={false}
          />
        </Stack>
        <Text size='xs' bold color={colors => colors.text[1]} pb='sm'>
          * Average wait times shown based on 2024 data. Actual wait times may vary by state,
          insurance and holidays/office closures.
        </Text>
        <PromptContentNavigation onBack={onBack} onSubmit={onNext} />
      </Stack>
    </Box>
  )
}
