import { useForm } from '@mantine/form'
import { Alert, Radio, RadioGroup, ShieldIcon, Stack, validateWith } from '@shared/components'
import { PromptResponseContextKey } from '@shared/types'
import React from 'react'
import { isRequired } from '../../../forms'
import { PromptContentNavigation } from '../PromptContentNavigation'
import { WidgetProps } from '../PromptContentWidget'

type Form = {
  careReadiness: PromptResponseContextKey | undefined
}

const READY_TO_START = 'care_readiness_yes' as PromptResponseContextKey
const NOT_READY = 'care_readiness_no' as PromptResponseContextKey

/**
 *
 * This component is used in v14 of the welcome flow and captures
 * the users care readiness response. It does not controls any branching
 * for the chat experiment.
 */
export const CareReadinessValueProps = ({ response, onBack, onSubmit }: WidgetProps) => {
  const form = useForm<Form>({
    initialValues: {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      careReadiness: response?.value.contextKey as PromptResponseContextKey | undefined,
    },
    validate: {
      careReadiness: validateWith(isRequired),
    },
  })

  const submitHandler = () => {
    const { hasErrors } = form.validate()

    if (hasErrors) {
      return
    }
    if (!form.values.careReadiness) {
      return
    }

    return onSubmit({
      value: {
        contextKey: form.values.careReadiness,
      },
    })
  }

  return (
    <Stack spacing='lg' test-id='content:care-readiness'>
      <RadioGroup {...form.getInputProps('careReadiness')}>
        <Radio
          test-id='radio:care-readiness:yes'
          label={`I'd like to start as soon as possible`}
          value={READY_TO_START}
        />
        <Radio
          test-id='radio:care-readiness:no'
          label={`I'm not sure, I'd like a little more information first`}
          value={NOT_READY}
        />
      </RadioGroup>
      <Alert icon={<ShieldIcon />} variant='primary'>
        Our care coordination team is available 7 days a week to help you navigate issues with your
        pharmacy, insurance, and prescriptions.
      </Alert>
      <PromptContentNavigation onBack={onBack} onSubmit={submitHandler} />
    </Stack>
  )
}
