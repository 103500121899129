import { NestedKeyOf, WELLNESS_CHECK_PATHNAME, WELLNESS_CHECK_START } from '@shared/types'
import get from 'lodash/get'

export const routes = {
  welcome: {
    index: '/welcome',
    children: {
      signup: 'signup',
      signin: 'signin',
    },
  },
  onboarding: {
    index: '/onboarding',
    children: {
      workflowPrompt: '/:sessionId/prompt/:promptId',
      workflowSession: '/:sessionId/prompt',
      welcome: '/welcome-scheduled',
    },
  },
  reschedule: {
    index: '/reschedule',
    children: {
      appointment: '/:appointmentId',
      // @welcomeCallReschedulingExperiment
      welcomeCall: '/wc/:appointmentId',
    },
  },
  schedule: {
    index: '/schedule',
    children: {
      self: '/:calendarHoldId',
    },
  },
  scheduled: {
    index: '/scheduled',
    children: {
      workflowSessionId: '/:workflowSessionId',
    },
  },
  chat: {
    index: '/chat',
    children: {
      workflowSessionId: '/:workflowSessionId',
    },
  },
  reconnect: {
    index: '/reconnect',
    children: {
      workflowPrompt: '/:sessionId/prompt/:promptId',
    },
  },
  portal: {
    index: '/portal',
    children: {
      account: 'account',
      achievements: 'achievements',
      task: {
        index: 'task',
        children: {
          profile: '/profile',
          insurance: '/insurance',
          scheduleIntakeVisit: '/schedule-intake-visit',
          scheduleFollowUpVisit: '/schedule-follow-up-visit',
          emergencyContact: '/emergency-contact',
          billing: '/billing',
          financialAgreement: '/financial-agreement',
          uploadId: '/upload-id',
          zoom: '/zoom',
          hdyhau: '/how-did-you-hear-about-us',
          medicalIntakeForm: '/medical-intake-form',
          preferredPharmacy: '/preferred-pharmacy',
          phq9Form: '/phq-9-form',
          phq8Form: '/phq-8-form',
          gad7Form: '/gad-7-form',
          barc10Form: '/barc-10-form',
          thankYouNote: '/thank-you-note',
          asqForm: {
            index: '/asq-form',
            children: {
              message: '/message',
            },
          },
          treatmentConsent: '/treatment-consent',
          treatmentConsentAndFinancialAgreement: '/treatment-consent-and-financial-agreement',
          tpoConsent: '/tpo-consent',
        },
        workflowPrompt: '/:sessionId/prompt/:promptId',
      },
      visits: 'visits',
      referrals: 'referrals',
      prescriptions: 'prescriptions',
      billing: {
        index: 'billing',
        children: {
          paymentMethods: {
            index: 'payment-methods',
            children: {
              add: '/add',
              remove: '/remove/:paymentMethodId',
              edit: '/edit/:paymentMethodId',
            },
          },
          addPayment: '/add-payment',
        },
      },
      intakeVisitStandbyList: 'intake-visit-standby-list',
      reschedule: {
        index: 'reschedule',
        children: {
          intake: '/intake',
          appointment: '/:appointmentId',
        },
      },
      documents: {
        index: 'documents',
        children: {
          financialAgreement: '/financial-agreement/:version',
          treatmentConsent: '/treatment-consent',
          releaseOfInformation: '/release-of-information',
          tpo: '/tpo',
        },
      },
    },
  },
  referrals: {
    index: '/referrals',
    children: {
      workflowPrompt: '/:sessionId/prompt/:promptId',
    },
  },
  referralsWithPatient: {
    index: '/referrals-with-patient',
    children: {
      workflowPrompt: '/:sessionId/prompt/:promptId',
    },
  },
  options: {
    index: '/options',
    children: {
      workflowPrompt: '/:sessionId/prompt/:promptId',
    },
  },
  wellnessCheck: {
    index: `/${WELLNESS_CHECK_PATHNAME}`,
    children: {
      start: `/${WELLNESS_CHECK_START}`,
      workflowSession: '/:sessionId',
      workflowPrompt: '/:sessionId/prompt/:promptId',
      completed: '/completed',
      expired: '/expired',
      asqMessage: '/asq-message',
    },
  },
  accountRecoveryRequest: {
    index: '/account-recovery-request',
    children: {
      update: '/update',
    },
  },
  resetPassword: { index: '/reset-password' },
  signout: { index: '/signout' },
  callMeNow: {
    index: '/call-me-now/:appointmentId',
  },
  confirmation: { index: '/confirmation/:appointmentId' },
  dropInClinic: { index: '/clinic/:enrollmentId' },
  meeting: { index: '/meeting/:meetingId' },
  costAnalysis: { index: '/cost-analysis' },
  insuranceCoverage: { index: '/insurance-coverage' },
  signedOut: { index: '/signed-out' },
} as const

/**
 * This is a convenience function to get the full path of a nested sub path.
 * Example: getFullPath('portal.children.account') returns '/portal/account'
 */
export const getFullPath = (path: NestedKeyOf<typeof routes>) => {
  const parts = []

  let currentPath = path

  while (currentPath) {
    // Get the current path (eg: 'foo.bar.baz')
    const currentPathValue = get(routes, currentPath)

    // base case
    if (!currentPathValue) {
      break
    }

    if (typeof currentPathValue === 'string') {
      parts.unshift(currentPathValue)
    }

    if (typeof currentPathValue === 'object' && 'index' in currentPathValue) {
      parts.unshift(currentPathValue.index)
    }

    // Remove the last part of the path (eg: 'foo.bar.baz' -> 'foo.bar') and continue
    currentPath = currentPath.split('.').slice(0, -1).join('.') as NestedKeyOf<typeof routes>
  }

  return parts
    .map(part => {
      // Ensure that the path starts with a '/'
      return part.startsWith('/') ? part : `/${part}`
    })
    .join('')
}
