import {
  Group,
  PrimaryButton,
  PrinterIcon,
  SecondaryButton,
  Stack,
  Text,
  TitleTwo,
  useBanner,
  useLifecycle,
} from '@shared/components'
import { dayjs, name } from '@shared/utils'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as FullStory from '../../../../../common/fullstory'
import { IntakeTaskExpirationAlert } from '../../../../../scheduled/pages/IntakeTaskExpirationAlert'
import { referralsApi } from '../../../../api'
import { useLDClient, usePortalQuery } from '../../../../hooks'
import { logger } from '../../../../logger'
import { routes } from '../../../../routes'
import { sendPageEvent } from '../../../../rudderstack'
import { removeSessionStorageItem } from '../../../../storage'
import { Skeletons } from '../../../Skeletons'

export const CorrectionalFacilityIntakeVisitConfirmed = () => {
  const navigate = useNavigate()

  const restartFlowHandler = () => {
    removeSessionStorageItem('referral_workflow_state')
    removeSessionStorageItem('referral_workflow_date')
    removeSessionStorageItem('referral_workflow_type')

    navigate(routes.referrals.index)
  }

  const { sessionId } = useParams()
  const { showBanner } = useBanner()
  const { ldClient } = useLDClient()

  useLifecycle({
    onMount: () => {
      if (sessionId) {
        void referralsApi.createReferralForSessionIfNotExists({ sessionId })
      }
    },
  })

  const appointmentQuery = usePortalQuery(
    'GET /appointments/onboarding',
    {
      query: { workflowSessionId: sessionId as string },
    },
    {
      enabled: Boolean(sessionId),
    },
  )

  const appointmentData = appointmentQuery.data

  useLifecycle({
    onMount: () => {
      showBanner({ type: 'success', label: 'Intake appointment scheduled' })

      if (ldClient) {
        ldClient.track('Correctional Facility Intake Scheduled')
      }
      sendPageEvent(`Correctional Facility Intake Scheduled in Referral Flow`)
      FullStory.event('Correctional Facility Intake Scheduled in Referral Flow')

      logger.info('Correctional Facility Intake Scheduled in Referral Flow', {
        tags: { workflow: 'onboarding' },
      })
    },
  })

  if (appointmentData?.type !== 'Initial Visit') {
    return null
  }

  const appointmentDate = dayjs(appointmentData?.datetime)
  const employeeName = appointmentData?.employeeName

  const fullName = name({
    first: appointmentData?.firstName,
    last: appointmentData?.lastName,
  }).full()

  if (appointmentQuery.isLoading || !appointmentData) {
    return <Skeletons />
  }

  return (
    <Stack spacing='lg' test-id='content:correctional-intake-visit-confirmed'>
      <TitleTwo className='print-content'>
        {`${fullName}'s virtual intake visit is scheduled for ${dayjs(appointmentDate).format(
          'h:mma z',
        )} on ${dayjs(appointmentDate).format('dddd MMM D YYYY')}${
          employeeName ? ` with ${employeeName}` : ''
        }`}
      </TitleTwo>
      <Text className='print-content'>
        {`After release, the patient will receive a confirmation email with a link to the patient portal, where they'll find the tasks that need to be completed before the intake visit.`}
      </Text>
      <Text className='print-content'>
        These tasks require access to a computer or smartphone and may require a credit or debit
        card. Ophelia will also attempt to reach out via phone call prior to the intake visit.
      </Text>
      <IntakeTaskExpirationAlert
        expirationDatetime={appointmentData?.patientTaskExpirationDatetime}
        isReferral
      />
      <Group position='apart' className='no-print'>
        <SecondaryButton leftIcon={<PrinterIcon />} onClick={() => print()}>
          Print
        </SecondaryButton>
        <PrimaryButton onClick={restartFlowHandler}>Make another referral</PrimaryButton>
      </Group>
    </Stack>
  )
}
