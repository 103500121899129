import { WelcomeFlowExperimentName } from '@shared/types'
import * as FullStory from '../fullstory'
import { analytics, sendTrackEvent } from '../rudderstack'
import { useMyQuery } from './use-my-query'

type ExperimentInfo = {
  experimentName: WelcomeFlowExperimentName
  isActive: boolean
}

/*
 * IMPORTANT!
 * The order in which experiments are listed here determines the order in which
 * patients will be redirected to the appropriate page. For example, if a patient
 * is sorted into the test cohort for the first experiment, they will be redirected
 * to the first experiment's redirect path, regardless of how the patient is sorted
 * into the remaining experiments.
 *
 * In practice, we should not be running multiple experiments that would interfere
 * with each other. So, if this becomes an issue, you should chat with product.
 */
const experimentInfo: ExperimentInfo[] = [
  // @note we'll wait to turn this on until we're ready to launch the experiment
  {
    experimentName: 'welcome-flow-value-props',
    isActive: false,
  },
]

/*
 * We only want to cohort patients into active experiments. Active experiments are
 * defined as experiments that do not have an end date.
 */
const activeExperimentsToCohort = experimentInfo.filter(experiment => experiment.isActive)

const activeExperimentNames = activeExperimentsToCohort.map(experiment => experiment.experimentName)

type ActiveExperimentName = (typeof activeExperimentNames)[number]

export const useWelcomeFlowExperimentCohorting = ({
  experimentName,
}: {
  experimentName: ActiveExperimentName
}) => {
  const anonymousId = analytics.getAnonymousId()

  const cohortQuery = useMyQuery(
    'POST /public/settings/cohort',
    {
      data: {
        anonymousId,
        experimentName,
      },
    },
    {
      // Prevent retries on failure
      retry: false,
      // Prevent multiple simultaneous requests
      staleTime: Infinity,
      // Prevent cache updates from triggering reruns
      cacheTime: 0,
      onSuccess: data => {
        sendTrackEvent('Welcome Flow Experiment Cohorted', {
          experimentName,
          cohort: data?.data?.cohort,
        })
        FullStory.event('Welcome Flow Experiment Cohorted', {
          experimentName,
          cohort: data?.data?.cohort,
        })
      },
    },
  )

  const isLoadingCohort = cohortQuery.isLoading

  const cohort = cohortQuery.data?.data?.cohort

  return {
    isLoadingCohort,
    cohort,
  }
}
