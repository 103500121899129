import { Box, Stack, Text, TitleTwo } from '@shared/components'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { GettingStartedBox } from '../../../../welcome/pages/Signup/ValuePropsGettingStarted'
import { useAuth } from '../../../hooks'
import { useWelcomeChatAvailability } from '../../../hooks/use-chat-availability'
import { sendTrackEvent } from '../../../rudderstack'
import { PromptContentNavigation } from '../PromptContentNavigation'
import { WidgetProps } from '../PromptContentWidget'

/**
 * This component is used in v14 of the welcome flow
 * and branches patients to the chat experiment if it
 * is currently running and they are in the test cohort
 */
export const WhatComesNext = ({ onSubmit }: WidgetProps) => {
  const navigate = useNavigate()
  const { currentUser } = useAuth()
  const { isWelcomeChatAvailable } = useWelcomeChatAvailability()

  const submitHandler = () => {
    const shouldBranchToChatTest =
      currentUser?.data?.experiments?.['welcome-chat-cohort-split']?.cohort === 'test' &&
      isWelcomeChatAvailable

    sendTrackEvent('[Welcome Chat] Branching Determined', {
      isExperimentRunning: isWelcomeChatAvailable,
      cohort: currentUser?.data?.experiments?.['welcome-chat-cohort-split']?.cohort,
    })

    if (shouldBranchToChatTest) {
      return onSubmit({
        value: {
          contextKey: `what_comes_next_chat_test`,
        },
      })
    }

    return onSubmit({
      value: {
        contextKey: 'what_comes_next',
      },
    })
  }

  return (
    <Box test-id='page:value-props-what-comes-next'>
      <Stack spacing='md'>
        <TitleTwo>What comes next:</TitleTwo>
        <Stack spacing='sm'>
          <GettingStartedBox
            title='Online assessment'
            description='Answer questions about your experience with OUD and Suboxone so we can personalize your care.'
            timeline='~ 5 mins'
            isActiveStep={false}
            isCompletedStep
          />
          <GettingStartedBox
            title='Complete enrollment'
            description='Our enrollment team will go over cost, verify insurance and schedule your first visit.'
            timeline='Within 24 hours*'
            isActiveStep
          />
          <GettingStartedBox
            title='Meet clinician over Zoom'
            description='If we’re a fit, you will get a prescription and a personalized treatment plan. '
            timeline='Within 2-3 days*'
            isActiveStep={false}
          />
        </Stack>
        <Text size='xs' bold color={colors => colors.text[1]} pb='sm'>
          * Average wait times shown based on 2024 data. Actual wait times may vary by state,
          insurance and holidays/office closures.
        </Text>
        <PromptContentNavigation onBack={() => navigate(-1)} onSubmit={() => submitHandler()} />
      </Stack>
    </Box>
  )
}
