// @todo: remove eslint-disable once functionality is added
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLifecycle } from '@shared/components'
import { AppointmentStatus, AppointmentTypeString, ISOString, Patient } from '@shared/types'
import { dayjs } from '@shared/utils'
import React, { useState } from 'react'
import { Navigate, useParams, useSearchParams } from 'react-router-dom'
import { Skeletons } from '../../../../common/components'
import * as FullStory from '../../../../common/fullstory'
import { useMyQuery, usePortalQuery } from '../../../../common/hooks'
import { sendTrackEvent } from '../../../../common/rudderstack'
import { RequestCallMeNow } from './RequestCallMeNow'
import { RescheduleWelcomeCallCalendar } from './RescheduleWelcomeCallCalendar'
import { SuccessfullyRequestedCallMeNow } from './SuccessfullyRequestedCallMeNow'
import { RescheduledSuccessfully } from './SuccessfullyRescheduled'

/*
 * We're using the same UI to pre-emptively reschedule welcome calls and
 * reschedule welcome calls that are no-showed. Leads are going to be sent
 * to this page from a text campaign, and we want to track which campaign
 * referred the patient to this page, so we're using the query params below
 * to track which campaign referred the patient.
 */
const CAMPAIGN_REFERRAL_PARAM_KEY = 'utm_campaign'
const CAMPAIGN_REFERRAL_PARAM_VALUES = ['preemptive-rescheduling', 'no-show-rescheduling'] as const
export type CampaignReferralParamValue = (typeof CAMPAIGN_REFERRAL_PARAM_VALUES)[number]

export type AppointmentInfo = {
  id: number
  calendar: string
  status: AppointmentStatus
  datetime: string
  visitType: AppointmentTypeString
  duration: string
  confirmed: boolean
  patientId: Patient['oid']
}

/*
 * We'll use the same page, but will conditionally display content depending on what step the user is at.
 * To start, patients will arrive at a page to reschedule an appointment.
 */
export type ContentToDisplay =
  | 'reschedule_appointment'
  | 'appointment_successfully_rescheduled'
  | 'request_cmn'
  | 'cmn_successfully_requested'

const getCampaignReferrer = ({
  searchParams,
}: {
  searchParams: URLSearchParams
}): CampaignReferralParamValue | null => {
  const campaignParamValue = searchParams.get(CAMPAIGN_REFERRAL_PARAM_KEY)

  if (CAMPAIGN_REFERRAL_PARAM_VALUES.includes(campaignParamValue as CampaignReferralParamValue)) {
    return campaignParamValue as CampaignReferralParamValue
  }

  return null
}

export const RescheduleWelcomeCall = () => {
  const { appointmentId } = useParams()
  const [searchParams] = useSearchParams()

  const referrer = getCampaignReferrer({ searchParams })

  const [contentToDisplay, setContentToDisplay] =
    useState<ContentToDisplay>('reschedule_appointment')

  /*
   * If the patient reschedules their appointment, we want to show the new
   * datetime on the rescheduling success page. However, since the appointment
   * info gets set through a webhook, refetching the appointment info won't
   * update the appointment datetime. Therefore, we'll use a state variable
   * to track the appointment datetime if it gets updated.
   */
  const [appointmentDatetime, setAppointmentDatetime] = useState<ISOString>()

  useLifecycle({
    onMount: () => {
      // The lead should always be referred from a valid campaign but, if not, we'll provide a fallback value
      FullStory.event('[Welcome Call Experiment] Viewed Page', { referrer: referrer || 'Unknown' })
    },
  })

  const { isLoading: isLoadingAppointmentInfo, data: appointmentInfo } = usePortalQuery(
    'GET /appointments/:id/confirm',
    {
      params: { id: appointmentId as string },
    },
    {
      enabled: Boolean(appointmentId),
    },
  )

  /*
   * Ensure that this appointment is the patient's latest welcome call. If not,
   * redirect the patient to this page for their latest welcome call.
   */
  const { isLoading: isLoadingLatestActiveWelcomeCallInfo, data: latestActiveWelcomeCallInfo } =
    useMyQuery(
      'GET /appointments/wc/latest',
      {
        query: {
          patientId: appointmentInfo?.patientId || '',
        },
      },
      {
        enabled: Boolean(appointmentInfo?.patientId),
      },
    )

  if (isLoadingAppointmentInfo || isLoadingLatestActiveWelcomeCallInfo) {
    return <Skeletons />
  }

  /*
   * If the patient has an active welcome call, and the appointment
   * they're trying to reschedule is not the latest active welcome call,
   * redirect the patient to this page for their latest active welcome call.
   */
  if (
    latestActiveWelcomeCallInfo?.data.id &&
    `${latestActiveWelcomeCallInfo.data.id}` !== appointmentId
  ) {
    return (
      <Navigate
        to={`/reschedule/wc/${latestActiveWelcomeCallInfo?.data.id}?utm_campaign=preemptive-rescheduling`}
        replace
      />
    )
  }

  if (!appointmentInfo) {
    // @todo create one function that sends both FullStory and Rudderstack events
    FullStory.event('[Welcome Call Experiment] No Appointment Found')
    sendTrackEvent('[Welcome Call Experiment] No Appointment Found', {
      appointmentId,
      referrer: referrer || 'Unknown',
    })
    return <Navigate to='/' replace />
  }

  /*
   * If the welcome call cannot be rescheduled, redirect the user to the homepage.
   * Reasons for not being able to reschedule include:
   * - The appointment is not a welcome call
   * - The welcome call was already attended
   */
  const isWelcomeCall = appointmentInfo?.visitType === 'Free Consultation Call'
  const isAttendedAppointment = appointmentInfo?.status === 'attended'

  /*
   * @todo also, check if the patient already has a welcome call scheduled in the future.
   * If so, redirect them to the reschedule page.
   */
  const isAbleToRescheduleWelcomeCall = isWelcomeCall && !isAttendedAppointment

  if (!isAbleToRescheduleWelcomeCall) {
    FullStory.event('[Welcome Call Experiment] Not Able To Reschedule Welcome Call', {
      // These values need to be strings because FullStory doesn't support boolean values
      isWelcomeCall: isWelcomeCall ? 'Yes' : 'No',
      hasNotAlreadyAttendedAppointment: isAttendedAppointment ? 'No' : 'Yes',
    })
    sendTrackEvent('[Welcome Call Experiment] Not Able To Reschedule Welcome Call', {
      isWelcomeCall,
      hasNotAlreadyAttendedAppointment: !isAttendedAppointment,
    })
    return <Navigate to='/' replace />
  }

  /*
   * We want to ensure that patient's are rescheduling using the correct link.
   *
   * If the patient clicked on the pre-emptive rescheduling link, but the
   * appointment has been cancelled, we want to redirect them to the no-show
   * rescheduling link.
   *
   * Alternatively, if the patient clicked on the no-show rescheduling link,
   * but the appointment is in the future and has not been cancelled, we want to
   * redirect them to the pre-emptive rescheduling link.
   */
  const appointmentCancellationStatuses: AppointmentStatus[] = [
    'canceled',
    'no-show',
    'late-cancellation',
  ]
  const isCancelledAppointment = appointmentCancellationStatuses.includes(appointmentInfo.status)

  if (referrer === 'preemptive-rescheduling' && isCancelledAppointment) {
    FullStory.event('[Welcome Call Experiment] Clicked Preemptive Link But Is No Show')
    sendTrackEvent('[Welcome Call Experiment] Clicked Preemptive Link But Is No Show', {
      appointmentId,
    })

    return (
      <Navigate to={`/reschedule/wc/${appointmentId}?utm_campaign=no-show-rescheduling`} replace />
    )
  }

  if (
    referrer === 'no-show-rescheduling' &&
    dayjs(appointmentInfo.datetime).isAfter(dayjs()) &&
    !isCancelledAppointment
  ) {
    FullStory.event('[Welcome Call Experiment] Clicked No Show Link But Is Preemptive')
    sendTrackEvent('[Welcome Call Experiment] Clicked No Show Link But Is Preemptive', {
      appointmentId,
    })

    return (
      <Navigate
        to={`/reschedule/wc/${appointmentId}?utm_campaign=preemptive-rescheduling`}
        replace
      />
    )
  }

  switch (contentToDisplay) {
    case 'reschedule_appointment':
      return (
        <RescheduleWelcomeCallCalendar
          appointmentInfo={appointmentInfo}
          // There should always be a referrer, but if there isn't, we'll default to the no-show rescheduling link
          referrer={referrer || 'no-show-rescheduling'}
          updateContentToDisplay={(contentToDisplay: ContentToDisplay) =>
            setContentToDisplay(contentToDisplay)
          }
          getUpdatedAppointmentInfo={(datetime: ISOString) => setAppointmentDatetime(datetime)}
        />
      )
    case 'appointment_successfully_rescheduled':
      return (
        <RescheduledSuccessfully
          updatedAppointmentDatetime={appointmentDatetime}
          updateContentToDisplay={(contentToDisplay: ContentToDisplay) =>
            setContentToDisplay(contentToDisplay)
          }
        />
      )
    case 'request_cmn':
      return (
        <RequestCallMeNow
          appointmentInfo={appointmentInfo}
          updateContentToDisplay={(contentToDisplay: ContentToDisplay) =>
            setContentToDisplay(contentToDisplay)
          }
        />
      )
    case 'cmn_successfully_requested':
      return (
        <SuccessfullyRequestedCallMeNow
          updateContentToDisplay={(contentToDisplay: ContentToDisplay) =>
            setContentToDisplay(contentToDisplay)
          }
        />
      )
    /*
     * As a fallback, navigate the patient back to the home page
     */
    default:
      return <Navigate to='/' replace />
  }
}
