import {
  NOT_ENTERING_INDUCTION_VALUE,
  ProblemListProblem,
  ReferralOption,
  StateAbbr,
  SubstanceUseNoteContent,
} from '../..'

export const YES_OR_NO = ['yes', 'no'] as const
export type YesNo = (typeof YES_OR_NO)[number]

export const OUTSTANDING_BALANCE = ['fully_paid', 'partially_paid', 'not_paid', 'other'] as const
export type OutstandingBalance = (typeof OUTSTANDING_BALANCE)[number]

export const INSURANCE_DETERMINATION = ['In network', 'Out of network', 'Needs review'] as const
export type InsuranceDetermination = (typeof INSURANCE_DETERMINATION)[number]

export const INSURANCE_ACTIVE_BENEFITS = ['Yes', 'No', 'Needs review'] as const
export type InsuranceActiveBenefits = (typeof INSURANCE_ACTIVE_BENEFITS)[number]

export const INSURANCE_LINE_OF_BUSINESS = ['Commercial', 'Medicare', 'Medicaid', 'Other'] as const
export type InsuranceLineOfBusiness = (typeof INSURANCE_LINE_OF_BUSINESS)[number]

export const INSURANCE_PAYMENT_METHOD = ['Cash', 'Insurance'] as const
export type InsurancePaymentMethod = (typeof INSURANCE_PAYMENT_METHOD)[number]

type PosNeg = 'positive' | 'negative'

export type ClosedNoteDetails = {
  locked_at: string
  locked_by_name: string
  locked_by_calendar_id: number
  locked_by_user_id: string
  attestation?: Attestation
}

export type Attestation = {
  attestation_notes?: string
  attested_at: string
  attested_by_user_id: string
  attested_by_display_name: string
}

export type BarriersToTreatment = {
  barriers_to_treatment_none_or_no_barriers?: 'yes' | 'no'
  barriers_to_treatment_none_or_no_barriers_additional_details?: string
  barriers_to_treatment_cannot_afford_services?: 'yes' | 'no'
  barriers_to_treatment_cannot_afford_services_additional_details?: string
  barriers_to_treatment_wait_time_too_long?: 'yes' | 'no'
  barriers_to_treatment_wait_time_too_long_additional_details?: string
  barriers_to_treatment_lack_of_technology_access?: 'yes' | 'no'
  barriers_to_treatment_lack_of_technology_access_additional_details?: string
  barriers_to_treatment_clinical_services_not_offered?: 'yes' | 'no'
  barriers_to_treatment_clinical_services_not_offered_additional_details?: string
  barriers_to_treatment_preferred_appointment_time_not_offered?: 'yes' | 'no'
  barriers_to_treatment_preferred_appointment_time_not_offered_additional_details?: string
  barriers_to_treatment_does_not_have_required_documentation?: 'yes' | 'no'
  barriers_to_treatment_does_not_have_required_documentation_additional_details?: string
  barriers_to_treatment_other?: 'yes' | 'no'
  barriers_to_treatment_other_additional_details?: string
  barriers_to_treatment_not_discussed?: 'yes' | 'no'
  barriers_to_treatment_not_discussed_additional_details?: string
  primary_barrier_to_treatment?: string
}

type VisitNoteContentInsurance = {
  has_insurance?: YesNo
  insurance_provider?: string
  other_insurance_provider?: string
  has_medicaid?: YesNo
  insurance_payment_method?: InsurancePaymentMethod
  insurance_active_benefits?: InsuranceActiveBenefits
  insurance_line_of_business?: InsuranceLineOfBusiness
  insurance_determination?: InsuranceDetermination
  has_insurance_additional_details?: string
}

type VisitNoteContentMedicalHistory = {
  past_buprenorphone_exposure?: string
  past_buprenorphine_exposure_more_details?: string
}

type VisitNoteContentMotivationsForTreatment = {
  motivations_for_treatment_more_details: string
}

type VisitNoteContentCareTransferReasonForWelcomeCall = {
  care_transfer_reason_for_welcome_call: string
}

type ConsultationVisitNoteContentCore = {
  patient_story?: string
  treatment_history?: string
  referral?: ReferralOption
  referralPartnerId?: string
  referral_additional_details?: string
  provider?: string
  organization?: string
  referral_state?: string
  referral_city?: string
  was_referred?: YesNo
  additional?: string
  zoom_familiarity?: 'yes' | 'no'
  zoom_familiarity_additional_details?: string
} & BarriersToTreatment

type ReenrollmentCallVisitNoteContentCore = {
  outstanding_balance?: OutstandingBalance
  outstanding_balance_additional_details?: string
  bridge_prescription_eligibility?: string
  bridge_prescription_eligibility_additional_details?: string
} & BarriersToTreatment

export type ConsultationVisitNoteContent = ConsultationVisitNoteContentCore &
  VisitNoteContentInsurance &
  VisitNoteContentMedicalHistory &
  VisitNoteContentMotivationsForTreatment &
  VisitNoteContentCareTransferReasonForWelcomeCall &
  Partial<ClosedNoteDetails>

export type ReenrollmentCallVisitNoteContent = ReenrollmentCallVisitNoteContentCore &
  VisitNoteContentInsurance &
  VisitNoteContentMedicalHistory &
  VisitNoteContentMotivationsForTreatment &
  VisitNoteContentCareTransferReasonForWelcomeCall &
  Partial<ClosedNoteDetails>

export type OnboardingCallVisitNoteContent =
  | keyof ConsultationVisitNoteContent
  | keyof ReenrollmentCallVisitNoteContent

export type UDSVisitNoteContent = {
  drug_screen_administered: YesNo
  drug_screen_alcohol_result: PosNeg
  drug_screen_amphetamines_result: PosNeg
  drug_screen_barbiturates_result: PosNeg
  drug_screen_benzodiazepines_result: PosNeg
  drug_screen_buprenorphine_result: PosNeg
  drug_screen_cocaine_result: PosNeg
  drug_screen_fentanyl_result: PosNeg
  drug_screen_marijuana_result: PosNeg
  drug_screen_methadone_result: PosNeg
  drug_screen_methamphetamine_result: PosNeg
  drug_screen_methylenedioxymethamphetamine_result: PosNeg
  drug_screen_opiates_result: PosNeg
  drug_screen_oxycodone_result: PosNeg
  drug_screen_phencyclidine_result: PosNeg
  drug_screen_synthetic_cannabinoid_result: PosNeg
  drug_screen_tramadol_result: PosNeg
  drug_screen_comments?: string
} & Partial<ClosedNoteDetails>

export type PeerCheckinVisitNoteContent = {
  focus: string
  intervention: string
  plan: string
  potentialNeeds: string
  progress: string
  response: string
} & Partial<ClosedNoteDetails>

export type LegacyVisitNoteContent = {
  acuity_content?: string
  content?: string
} & Partial<ClosedNoteDetails>

export type FollowupBHCMVisitNoteContent = {
  symptoms: string
  objective_measures: string
  treatments: string
  plan_updates: string
  problems_addressed: ProblemListProblem[]
} & Partial<ClosedNoteDetails>

export type InitialBHCMVisitNoteContent = {
  symptoms: string
  objective_measures: string
  problems_addressed: ProblemListProblem[]
} & Partial<ClosedNoteDetails>

export type Addendum = {
  content: string
  id: string
  locked_at: string
  locked_by_name: string
  locked_by_user_id: string
}

export type InductionNoteContentForm = {
  participating_in_counseling: 'yes' | 'no'
  participating_in_recovery_services: 'yes' | 'no'
  witnessed_overdose: 'yes' | 'no'
  history_of_overdose: 'yes' | 'no'
  alcohol_use: 'yes' | 'no'
  tobacco_use: 'yes' | 'no'
  marijuana_use: 'yes' | 'no'
  benzodiazepines_use: 'yes' | 'no'
  amphetamine_use: 'yes' | 'no'
  cocaine_use: 'yes' | 'no'
  other_use: 'yes' | 'no'
  medication_name: string
  medication_strength: string
  medication_days_supply: string
  medication_quantity: string
  pdmp_consistent_with_history: 'yes' | 'no'
  plan: string
  induction_email: typeof NOT_ENTERING_INDUCTION_VALUE | string
  problems_addressed: ProblemListProblem[]
  effective_date: string
  overdose_history: 'yes' | 'no'
  naloxone_supply: string
  telehealth_consent: boolean
  history_of_present_illness: string
  medical_pmh: string
  psychiatric_pmh: string
  hospitalizations_pmh: string
  surgical_pmh: string
  active_medications_pmh: string
  allergies_and_reactions_pmh: string
  social_and_family_pmh: string
  opioid_history: string
  opioid_treatment_history: string
  overdose_history_comments: string
  witnessed_overdose_comments: string
  alcohol_use_comments: string
  tobacco_use_comments: string
  marijuana_use_comments: string
  benzodiazepines_use_comments: string
  amphetamine_use_comments: string
  cocaine_use_comments: string
  other_use_comments: string
  participating_in_counseling_comments: string
  participating_in_recovery_services_comments: string
  observed_and_examination: string
  pdmp_consistent_with_history_comments: string
  treatment_goals: string
  assessment: string
  heroin_or_fentanyl_regular_basis: 'yes' | 'no' | undefined
  suboxone_use_last_week: 'yes' | 'no' | undefined
  [key: `plan_${string}`]: string
  employee_address: string
  verified_patient_location: boolean
  patient_city: string
  patient_state: StateAbbr
  substanceUse?: SubstanceUseNoteContent
  addendum?: Addendum[]
}

export type InductionNoteContent = InductionNoteContentForm & ClosedNoteDetails
