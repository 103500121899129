import { Box, Divider, Group, Stack, Text, TitleTwo } from '@shared/components'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PromptContentNavigation } from '../../../common/components/prompt/PromptContentNavigation'
import { routes } from '../../../common/routes'

export const OfferBox = ({ title, description }: { title: string; description: string }) => {
  return (
    <Box
      sx={({ radius, other: { colors, sizes } }) => ({
        border: `${sizes.border.md} solid ${colors.background[2]}`,
        borderRadius: radius.sm,
      })}
    >
      <Group position='apart'>
        <Text p='sm' bold color={colors => colors.actions[0]}>
          {title}
        </Text>
      </Group>
      <Divider size='lg' color={colors => colors.background[1]} sx={{ width: '100%' }} />
      <Text p='sm'>{description}</Text>
    </Box>
  )
}

export const ValuePropsOffers = ({ onNext }: { onNext: () => void }) => {
  const navigate = useNavigate()

  const onBack = () => {
    navigate(routes.welcome.children.signup)
  }

  return (
    <Box test-id='page:value-props-offers'>
      <Stack spacing='md'>
        <Stack spacing='xs'>
          <TitleTwo>The first step to treatment is a BIG one.</TitleTwo>
          <Text>We offer:</Text>
        </Stack>
        <Stack spacing='sm'>
          <OfferBox
            title='Non-judgmental care'
            description='No stigma around drug use, no matter what you’re using.'
          />
          <OfferBox
            title='Harm reduction model'
            description='No surprise drug tests and no punitive measures.'
          />
          <OfferBox
            title='Personalized care'
            description='No one-size fits all approach — a care plan built around you.'
          />
        </Stack>
        <PromptContentNavigation onBack={onBack} onSubmit={onNext} />
      </Stack>
    </Box>
  )
}
