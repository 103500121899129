import { useForm } from '@mantine/form'
import {
  ArrowLeftIcon,
  Box,
  Group,
  NumberInput,
  PrimaryButton,
  ResponsiveBox,
  Stack,
  TertiaryButton,
} from '@shared/components'
import { PromptResponseModel } from '@shared/types'
import React from 'react'
import { isRequired } from '../../forms'

type Props = {
  value: number | undefined
  onSubmit: (data: PromptResponseModel['payload']) => void
  onBack: (() => void) | undefined
}

type Form = {
  value: number
}

export const PromptContentNumberInput = (props: Props) => {
  const form = useForm<Form>({
    initialValues: { value: props.value ?? 0 },
    validate: { value: isRequired },
  })

  const onSubmit = () => {
    if (form.validate().hasErrors) {
      return
    }

    props.onSubmit({ value: { contextKey: 'custom', value: form.values.value } })
  }

  return (
    <Box test-id='content:number-input'>
      <NumberInput {...form.getInputProps('value')} />
      <ResponsiveBox
        mt='lg'
        mobile={
          <Stack spacing='lg' justify='center'>
            <PrimaryButton test-id='button:next@mobile' fullWidth onClick={onSubmit}>
              Next
            </PrimaryButton>
            {props.onBack && (
              <TertiaryButton
                test-id='button:back@mobile'
                leftIcon={<ArrowLeftIcon />}
                style={{ margin: 'auto' }}
                onClick={props.onBack}
                fullWidth
              >
                Back
              </TertiaryButton>
            )}
          </Stack>
        }
        desktop={
          props.onBack ? (
            <Group position='apart'>
              <TertiaryButton
                test-id='button:back@desktop'
                leftIcon={<ArrowLeftIcon />}
                onClick={props.onBack}
              >
                Back
              </TertiaryButton>
              <PrimaryButton test-id='button:next@desktop' onClick={onSubmit}>
                Next
              </PrimaryButton>
            </Group>
          ) : (
            <PrimaryButton test-id='button:next@desktop' onClick={onSubmit}>
              Next
            </PrimaryButton>
          )
        }
      />
    </Box>
  )
}
